import { IModuleDefinition } from 'modules/types'
import { lazy } from 'react'

const HRContainer = lazy(() => import('./HRContainer'))

const moduleDefinition: IModuleDefinition = {
  routeProps: {
    path: '/hr/*',
    private: true,
    component: HRContainer
  },
  name: 'HR Dashboard'
}

export default moduleDefinition
