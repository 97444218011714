import { skipToken } from '@reduxjs/toolkit/query'
import { USDCell } from 'modules/ExternalManagers/WidgetTable'
import { useMemo } from 'react'
import {
  IFeeDetailRequest,
  useGetFeesFromOtherAccountsQuery
} from '../../../api'

export const FeesFromOtherAccounts: React.FC<Partial<IFeeDetailRequest>> = ({
  accountNumber,
  billDate
}) => {
  const { data } = useGetFeesFromOtherAccountsQuery(
    accountNumber && billDate ? { accountNumber, billDate } : skipToken
  )

  const withoutCurrentAccount = useMemo(
    () => data?.filter((x) => x.accountNumber !== accountNumber),
    [accountNumber, data]
  )

  return (
    <table
      css={{
        padding: '10px',
        '& th, & td': { padding: '1px 5px' }
      }}
    >
      <thead>
        <tr>
          <th>Account</th>
          <th>Manager Fee</th>
          <th>RCM Fee</th>
          <th>Client Fee</th>
          <th>Platform Fee</th>
          <th>Total Fee</th>
        </tr>
      </thead>
      <tbody>
        {withoutCurrentAccount?.map(
          ({
            accountNumber,
            pendingManagerFee,
            pendingRockFee,
            pendingClientFee = 0,
            pendingPlatformFee = 0
          }) => (
            <tr key={accountNumber}>
              <td>{accountNumber}</td>
              <td>
                <USDCell value={pendingManagerFee} />
              </td>
              <td>
                <USDCell value={pendingRockFee} />
              </td>
              <td>
                <USDCell value={pendingClientFee} />
              </td>
              <td>
                <USDCell value={pendingPlatformFee} />
              </td>
              <td>
                <USDCell value={pendingClientFee + pendingPlatformFee} />
              </td>
            </tr>
          )
        )}
      </tbody>
    </table>
  )
}
