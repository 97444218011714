import { Stack } from '@fluentui/react'
import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { LoadingComponent } from '../../../../../../shared/components/Loading'
import { getEnableDataMaskingPreference } from '../../../../../../store/user/selectors'
import { IListsFacetFilter } from '../../../contracts/IListsFilter'
import { ListsFacetActions } from '../../../store/actions'
import { ListsFacetSelectors } from '../../../store/selectors'
import { ListsFacetFilterComponent } from '../components/ListsFacetFilter'
import { IListsFilterEditProps } from '../contracts/IListsFilterEditProps'

export interface IListsFacetFilterProps extends IListsFilterEditProps {
  filter: IListsFacetFilter
}

export const createConnectedListsFacetFilter = (
  facetActions: ListsFacetActions,
  facetSelectors: ListsFacetSelectors
) => {
  const ListsStringFilterContainer: React.FC<IListsFacetFilterProps> = ({
    onChange,
    filter
  }) => {
    const onFilterChange = useCallback(
      (selectedValues: string[]) => {
        const newFilter: IListsFacetFilter = {
          ...filter,
          values: selectedValues,
          hasValue: selectedValues.length > 0
        }
        onChange(newFilter)
      },
      [filter, onChange]
    )

    const { id, values, mask, validFacetValues } = filter

    const facets = useSelector(facetSelectors.getFacetItems)
    const isFacetLoading = useSelector(facetSelectors.getIsLoading)
    const shouldMask = useSelector(getEnableDataMaskingPreference)
    const facet = facets?.[id]
    const dispatch = useDispatch()

    useEffect(() => {
      if (!facet) {
        dispatch(
          facetActions.request({
            id
          })
        )
      }
    }, [facet, dispatch, id])

    return (
      <Stack styles={{ root: { minHeight: '150px' } }}>
        {isFacetLoading ? (
          <LoadingComponent />
        ) : (
          <ListsFacetFilterComponent
            facets={
              validFacetValues
                ? facet?.filter((f) => validFacetValues.includes(f.value))
                : facet
            }
            selectedValues={values}
            onChange={onFilterChange}
            maskValues={shouldMask && mask}
          />
        )}
      </Stack>
    )
  }
  return ListsStringFilterContainer
}
