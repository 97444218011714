import {
  Calendar,
  CommandBar,
  CommandBarButton,
  ContextualMenu,
  DateRangeType,
  DetailsListLayoutMode,
  ICommandBarItemProps,
  Link,
  makeStyles,
  ProgressIndicator,
  Text
} from '@fluentui/react'
import { endOfMonth, startOfMonth } from 'date-fns'
import { DateOnlyCell } from 'features/Lists/core/components/DateCell'
import { constants } from 'modules/Advisory/shared/theme'
import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useDomainStore } from '../../../../../../features/Domain/store/domain'
import { TextCell } from '../../../../../../features/Lists/core/components/TextCell'
import { USDCell } from '../../../../../../features/Lists/core/components/USDCell'
import {
  IListsDateRangeFilter,
  IListsFacetFilter
} from '../../../../../../features/Lists/core/contracts/IListsFilter'
import {
  IOdataDataListCellRenderProps,
  OdataList
} from '../../../../../../features/OdataList/containers/OdataList'
import { ConnectedMasked } from '../../../../../../shared/components/MaskedText'
import { IRockConnectAssetFee } from '../api/rockConnectAssetFee'
import { useFeeDashboardExport } from './export'
import { FeesFromOtherAccountsHover } from './features/FeesFromOtherAccounts/FeesFromOtherAccountsHover'
import { PendingClientFeesHover } from './features/PendingClientFee/PendingClientFeesHover'
import { PlatformFeeDetailHover } from './features/PlatformFeeDetail'
import {
  feeDashboardListFacetActions,
  feeDashboardListFacetSelectors,
  feeDashboardListUiActions,
  feeDashboardListUiSelectors
} from './store/dashboardList'
import { FeeDashboardListColumnName } from './store/dashboardListColumns'

const CellComponents: Record<
  FeeDashboardListColumnName,
  React.FC<IOdataDataListCellRenderProps<IRockConnectAssetFee>>
> = {
  'Account Number': ({ item }) => (
    <TextCell>
      <ConnectedMasked text={item.accountNumber} />
    </TextCell>
  ),
  'Legal Entity Name': ({ item }) => (
    <TextCell>
      <ConnectedMasked text={item?.account?.party?.partyName} />
    </TextCell>
  ),
  'Bill Date': ({ item }) => <DateOnlyCell value={item.billDate} />,
  'Env Bill Period Start': ({ item }) => (
    <DateOnlyCell value={item.envBillPeriodStart} />
  ),
  'Env Bill Period End': ({ item }) => (
    <DateOnlyCell value={item.envBillPeriodEnd} />
  ),
  'Pending Manager Fee': ({ item }) => (
    <USDCell value={item.pendingManagerFee || 0} />
  ),
  'Pending Rockefeller Fee': ({ item }) => (
    <USDCell value={item.pendingRockFee || 0} />
  ),
  'Pending Client Fee': ({ item }) =>
    item.accountNumber && item.billDate && item.pendingClientFee ? (
      <PendingClientFeesHover
        accountNumber={item.accountNumber}
        billDate={item.billDate}
      >
        <Text variant="small">
          <Link>
            <USDCell value={item.pendingClientFee} />
          </Link>
        </Text>
      </PendingClientFeesHover>
    ) : (
      <USDCell value={item.pendingClientFee || 0} />
    ),
  'Pending Platform Fee': ({ item }) =>
    item.pendingPlatformFee && item.billDate ? (
      <PlatformFeeDetailHover
        accountNumber={item.accountNumber}
        billDate={item.billDate}
      >
        <Link>
          <USDCell value={item.pendingPlatformFee} />
        </Link>
      </PlatformFeeDetailHover>
    ) : (
      <USDCell value={item.pendingPlatformFee || 0} />
    ),
  'Debit Account Number': ({ item }) => (
    <TextCell>{item.debitAccountNumber}</TextCell>
  ),
  'Managed Model Type': ({ item }) => <TextCell>{item.modelType}</TextCell>,
  'Rep Code': ({ item }) => <TextCell>{item.account?.registeredRep}</TextCell>,
  'Platform Fee Period Start': ({ item }) => (
    <DateOnlyCell value={item.platformFeePeriodStart} />
  ),
  'Platform Fee Period End': ({ item }) => (
    <DateOnlyCell value={item.platformFeePeriodEnd} />
  ),
  'COB Managed Assets': ({ item }) => (
    <USDCell value={item?.account?.accountMetrics?.aumManaged} />
  ),
  'Fees From Other Accounts': ({ item }) =>
    item.feeFromOtherAccounts && item.billDate ? (
      <FeesFromOtherAccountsHover
        accountNumber={item.accountNumber}
        billDate={item.billDate}
      >
        <Link>
          <USDCell value={item.feeFromOtherAccounts} />
        </Link>
      </FeesFromOtherAccountsHover>
    ) : (
      <USDCell value={item.feeFromOtherAccounts || 0} />
    ),
  'Total Pending Debit': ({ item }) => (
    <USDCell value={item.totalPendingDebit || 0} />
  ),
  'Cash Available To Withdraw': ({ item }) => (
    <USDCell value={item.cashAvailable} />
  ),
  'Remaining Cash After Total Pending Debit': ({ item }) => (
    <USDCell value={item.remainingCash} />
  ),
  'Rep Name': ({ item }) => <TextCell>{item?.account?.advisor?.name}</TextCell>,
  Team: ({ item }) => <TextCell>{item?.account?.advisor?.teamName}</TextCell>,
  Division: ({ item }) => (
    <TextCell>{item?.account?.advisor?.regionName}</TextCell>
  ),
  Household: ({ item }) => (
    <TextCell>{item?.account?.household?.householdName}</TextCell>
  ),
  'Managed Model Name': ({ item }) => (
    <TextCell>{item?.account?.modelName}</TextCell>
  ),
  'Margin Agreement': ({ item }) => (
    <TextCell>{item?.account?.marginAgreement ? 'Yes' : 'No'}</TextCell>
  )
}

const FeeDashboardListCell: React.FC<
  IOdataDataListCellRenderProps<IRockConnectAssetFee>
> = memo(({ item, column }) => {
  const Component = CellComponents[column.name as FeeDashboardListColumnName]
  return Component ? <Component item={item} column={column} /> : null
})

const useClasses = makeStyles((theme) => ({
  commandBar: {
    borderBottom: `solid 2px ${theme.palette.neutralLight}`
  }
}))

export const FeeDashboardList: React.FC = () => {
  const dispatch = useDispatch()
  const containerRef = useRef<HTMLDivElement>(null)
  const [offsetLeft, setOffsetLeft] = useState(0)
  useEffect(() => {
    const offset = containerRef.current?.getBoundingClientRect()
    setOffsetLeft(offset?.left || 0)
  }, [])

  const { selectedAccountRepIds, isFullDomainSelected, domain } =
    useDomainStore()
  const filters = useSelector(feeDashboardListUiSelectors.getFilters)

  useEffect(() => {
    if (selectedAccountRepIds == null) {
      return
    }
    const filterId = 'Rep Code'
    const hasValue = !isFullDomainSelected && !!selectedAccountRepIds?.length
    dispatch(
      feeDashboardListUiActions.updateFilters({
        [filterId]: {
          ...filters?.[filterId],
          values: hasValue ? selectedAccountRepIds : undefined,
          type: 'facet',
          facets: domain?.map(({ rcm_repid }) => ({ value: rcm_repid })),
          hasValue
        } as IListsFacetFilter
      })
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAccountRepIds])

  const { exportToExcel, isLoading } = useFeeDashboardExport()
  const onBillPeriodChange = useCallback(
    (date?: Date) => {
      if (!date) {
        return
      }

      const filterId = 'Bill Date'
      dispatch(
        feeDashboardListUiActions.updateFilters({
          [filterId]: {
            ...filters?.[filterId],
            range: 'Custom',
            from: startOfMonth(date),
            to: endOfMonth(date),
            hasValue: true
          } as IListsDateRangeFilter
        })
      )
    },
    [dispatch, filters]
  )

  const commandBarItems = useMemo(() => {
    const items: ICommandBarItemProps[] = [
      {
        key: 'billing-period',
        text: 'Select Bill Date',
        iconProps: { iconName: 'Calendar' },
        commandBarButtonAs: (props: any) => (
          <CommandBarButton
            {...props}
            menuAs={(menuProps) => (
              <ContextualMenu
                {...(menuProps || {})}
                items={menuProps?.items || []}
                onRenderMenuList={() => (
                  <Calendar
                    onSelectDate={onBillPeriodChange}
                    dateRangeType={DateRangeType.Month}
                    isDayPickerVisible={false}
                    minDate={new Date(2019, 0, 1)}
                    maxDate={new Date()}
                  />
                )}
              />
            )}
          />
        ),
        subMenuProps: {
          items: [{ key: 'filler' }]
        }
      },
      {
        key: 'export',
        name: 'Export to Excel',
        iconProps: { iconName: 'ExcelLogo' },
        onClick: () => {
          exportToExcel()
        },
        disabled: isLoading
      }
    ]
    return items
  }, [exportToExcel, isLoading, onBillPeriodChange])

  const classes = useClasses()
  const secondaryHeader = useMemo(
    () => (
      <>
        <CommandBar
          className={classes.commandBar}
          items={commandBarItems}
          styles={{ root: { padding: 0, margin: 0 } }}
        />
        <div style={{ height: '2px' }}>
          <ProgressIndicator
            progressHidden={!isLoading}
            styles={{
              itemProgress: { padding: 0, margin: 0 }
            }}
          />
        </div>
      </>
    ),
    [classes.commandBar, commandBarItems, isLoading]
  )

  return (
    <div ref={containerRef}>
      <OdataList
        actions={feeDashboardListUiActions}
        selectors={feeDashboardListUiSelectors}
        facetActions={feeDashboardListFacetActions}
        facetSelectors={feeDashboardListFacetSelectors}
        onRenderCell={FeeDashboardListCell}
        stickyHeaderOffset={constants.headerHeight}
        stickyColumnOffset={offsetLeft}
        secondaryHeader={secondaryHeader}
        layoutMode={DetailsListLayoutMode.fixedColumns}
        numStickyColumns={2}
        autoLoad={false}
      />
    </div>
  )
}
