import { IManagedAccountFeeHistory } from 'api/datahub'
import { IOdataResult } from 'shared/contracts/IOdataResult'
import { datahubApi } from 'store/api/datahub'
import { arrayCommaParamsSerializer } from 'store/api/shared'
import { IPlatformFeeHistoryModel } from './platformFeeHistory'
import { IRockConnectAssetFee } from './rockConnectAssetFee'

export interface IFeeDetailRequest {
  accountNumber: string
  billDate: string
}

const feesApi = datahubApi.injectEndpoints({
  endpoints: (builder) => ({
    getFeesFromOtherAccounts: builder.query<
      IRockConnectAssetFee[] | undefined,
      IFeeDetailRequest
    >({
      query: ({ accountNumber, billDate }) => ({
        url: 'RockConnectAssetFees',
        params: {
          $filter: `debitAccountNumber eq '${accountNumber}' and billDate eq ${billDate}`,
          $orderBy: 'totalPendingDebit desc'
        },
        paramsSerializer: arrayCommaParamsSerializer
      }),
      keepUnusedDataFor: 60 * 60 * 2,
      transformResponse: (x: IOdataResult<IRockConnectAssetFee>) => x?.value
    }),
    getPlatformFeeHistory: builder.query<
      IPlatformFeeHistoryModel[] | undefined,
      IFeeDetailRequest
    >({
      query: ({ accountNumber, billDate }) => ({
        url: 'PlatformFeeHistory',
        params: {
          $filter: `accountNumber eq '${accountNumber}' and feeSchedule/billDate eq ${billDate}`,
          $top: 1,
          $select: [
            'accountNumber',
            'billStartDate',
            'billEndDate',
            'unadjustedAum',
            'adjustedAumAfterCap',
            'grossFee',
            'grossFeePct',
            'creditFee',
            'creditFeePct',
            'netFee',
            'netFeePct'
          ]
        },
        paramsSerializer: arrayCommaParamsSerializer
      }),
      keepUnusedDataFor: 60 * 60 * 2,
      transformResponse: (x: IOdataResult<IPlatformFeeHistoryModel>) => x?.value
    }),
    getManagedAccountFeeDetails: builder.query<
      IManagedAccountFeeHistory[] | undefined,
      {
        accountNumber: string
        billDate: string
      }
    >({
      query: ({ accountNumber, billDate }) => {
        return {
          url: 'managedAccountFeeHistory',
          params: {
            $select: [
              'account',
              'billableValue',
              'billType',
              'managerFee',
              'rockefellerFee',
              'clientFee',
              'billPeriodStart',
              'billPeriodEnd'
            ],
            $filter: [
              `account eq '${accountNumber}'`,
              `billdate eq ${billDate}`
            ]
              .filter(Boolean)
              .join(' and '),
            $top: 10
          },
          paramsSerializer: arrayCommaParamsSerializer
        }
      },
      transformResponse: (response: IOdataResult<IManagedAccountFeeHistory>) =>
        response?.value
    })
  })
})

export const {
  useGetFeesFromOtherAccountsQuery,
  useGetPlatformFeeHistoryQuery,
  useGetManagedAccountFeeDetailsQuery
} = feesApi
