import { IColumnDefinition } from 'features/Lists/core/contracts/IColumnDefinition'
import { CommonColumnDefinitions } from 'features/Lists/shared/CommonColumnDefinitions'

export enum AlertColumnIdsEnum {
  checbox = 'checkbox',
  pin = 'pin',
  tag = 'tag',
  created = 'created',
  source = 'source',
  alertType = 'alertType',
  description = 'description',
  status = 'status',
  due = 'due',
  client = 'client',
  accountNumber = 'accountNumber',
  registrationType = 'registrationType',
  holdingsMarketValue = 'holdingsMarketValue',
  repCode = 'repCode',
  owner = 'owner',
  action = 'action',
  isArchived = 'isArchived'
}

export const ColumnName: Record<AlertColumnIdsEnum, string> = {
  checkbox: 'Checkbox',
  pin: ' ',
  tag: 'Tag',
  created: 'Created',
  source: 'Source',
  alertType: 'Alert Type',
  description: 'Description',
  status: 'Status',
  client: 'Account Name',
  accountNumber: 'Account #',
  registrationType: 'Registration Type',
  holdingsMarketValue: 'Alert Amount / Quantity',
  repCode: 'Rep Code',
  action: 'Action',
  due: 'Due',
  owner: 'Owner',
  isArchived: 'Is Archived'
} as const

export const AlertColumnIds = { ...AlertColumnIdsEnum }
export type AlertColumnIds = typeof AlertColumnIds

export const AlertColumnDefinitions: Record<string, IColumnDefinition> = {
  ...CommonColumnDefinitions,
  [AlertColumnIds.pin]: {
    id: AlertColumnIds.pin,
    name: AlertColumnIds.pin,
    type: 'string',
    width: 30,
    dataPath: 'id',
    select: [
      'id',
      'alertId',
      'clientid',
      'householdId',
      'alertDetails',
      'owner',
      'receivedWeek',
      'isArchived',
      'age'
    ]
  },
  [AlertColumnIds.tag]: {
    id: AlertColumnIds.tag,
    name: AlertColumnIds.tag,
    type: 'string',
    width: 30,
    select: ['id'],
    dataPath: 'id'
  },
  [AlertColumnIds.isArchived]: {
    id: AlertColumnIds.isArchived,
    name: AlertColumnIds.isArchived,
    type: 'string',
    width: 30,
    select: ['isArchived'],
    dataPath: 'isArchived'
  },
  [AlertColumnIds.created]: {
    id: AlertColumnIds.created,
    name: AlertColumnIds.created,
    select: ['created'],
    filterable: true,
    sortable: true,
    type: 'date',
    dataPath: 'created'
  },
  [AlertColumnIds.source]: {
    id: AlertColumnIds.source,
    name: AlertColumnIds.source,
    select: ['source'],
    searchFields: ['source'],
    searchable: true,
    sortable: true,
    facetable: true,
    type: 'string',
    dataPath: 'source'
  },
  [AlertColumnIds.alertType]: {
    id: AlertColumnIds.alertType,
    name: AlertColumnIds.alertType,
    select: ['alertType'],
    searchFields: ['alertType'],
    facetable: true,
    sortable: true,
    searchable: true,
    type: 'string',
    dataPath: 'alertType'
  },
  [AlertColumnIds.description]: {
    id: AlertColumnIds.description,
    name: AlertColumnIds.description,
    select: ['description'],
    searchFields: ['description'],
    searchable: true,
    sortable: true,
    filterable: true,
    type: 'string',
    dataPath: 'description'
  },
  [AlertColumnIds.status]: {
    id: AlertColumnIds.status,
    name: AlertColumnIds.status,
    select: ['status'],
    searchFields: ['status'],
    searchable: true,
    sortable: true,
    facetable: true,
    type: 'string',
    dataPath: 'status'
  },
  [AlertColumnIds.due]: {
    id: AlertColumnIds.due,
    name: AlertColumnIds.due,
    select: ['due'],
    sortable: true,
    filterable: true,
    type: 'date',
    dataPath: 'due'
  },
  [AlertColumnIds.client]: {
    id: AlertColumnIds.client,
    name: AlertColumnIds.client,
    select: ['client'],
    searchFields: ['client'],
    searchable: true,
    sortable: true,
    filterable: true,
    type: 'string',
    dataPath: 'client'
  },
  [AlertColumnIds.accountNumber]: {
    id: AlertColumnIds.accountNumber,
    name: AlertColumnIds.accountNumber,
    select: ['accountNumber'],
    searchFields: ['accountNumber'],
    searchable: true,
    sortable: true,
    filterable: true,
    type: 'string',
    dataPath: 'accountNumber'
  },
  [AlertColumnIds.registrationType]: {
    id: AlertColumnIds.registrationType,
    name: AlertColumnIds.registrationType,
    select: ['registrationType'],
    searchFields: ['registrationType'],
    type: 'string',
    width: 175,
    dataPath: 'registrationType',
    searchable: true,
    sortable: true,
    filterable: true
  },
  [AlertColumnIds.holdingsMarketValue]: {
    id: AlertColumnIds.holdingsMarketValue,
    name: AlertColumnIds.holdingsMarketValue,
    type: 'number',
    width: 70,
    filterable: true,
    sortable: true,
    dataPath: 'holdingsMarketValue',
    select: ['holdingsMarketValue'],
    searchFields: ['holdingsMarketValue']
  },
  [AlertColumnIds.repCode]: {
    id: AlertColumnIds.repCode,
    name: AlertColumnIds.repCode,
    dataPath: 'repCode',
    select: ['repCode'],
    searchFields: ['repCode'],
    type: 'string',
    width: 70,
    filterable: true,
    sortable: true,
    facetable: true
  },
  [AlertColumnIds.owner]: {
    id: AlertColumnIds.owner,
    name: AlertColumnIds.owner,
    dataPath: 'owner',
    searchFields: ['owner'],
    select: ['owner'],
    type: 'string',
    width: 70,
    filterable: true,
    sortable: true,
    searchable: true
  },
  [AlertColumnIds.action]: {
    id: AlertColumnIds.action,
    name: AlertColumnIds.action,
    dataPath: '',
    type: 'string',
    width: 80
  }
}
