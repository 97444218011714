import { format } from 'date-fns'
import {
  Dictionary,
  IDataFilter
} from 'shared/components/DataTableFilters/DataTableFilters'
import { IClient } from '../../../api/client.types'
import { IPlatFormFeeDetails, IPlatFormFeeFlows } from '../store/types'
import { numberFormat, amountFormat } from './Constants'

export function _copyAndSort<T>(
  items: T[],
  columnKey: string,
  isSortedDescending?: boolean
): T[] {
  const key = columnKey as keyof T
  return items
    .slice(0)
    .sort((a: T, b: T) =>
      (isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1
    )
}

export const isValidLegalEntityId = (legalId: string) => {
  let isValid = true
  const regexp = new RegExp(numberFormat)
  isValid = regexp.test(legalId)
  return isValid
}

export const formattedClientNameEntity = (client: IClient) => {
  return client?.LegalEntityID && isValidLegalEntityId(client?.LegalEntityID)
    ? `${client?.LegalEntityName} (${client?.LegalEntityID})`
    : client?.LegalEntityName
    ? `${client?.LegalEntityName}`
    : ''
}

export const isAmountFormat = (value: string) => {
  let isValid = false
  if (value) {
    const regexp = new RegExp(amountFormat)
    isValid = regexp.test(value)
  }

  return isValid
}

export const numberToCurrency = (value: number, currency?: string): string => {
  if (value) {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currency || 'USD'
    })

    return formatter.format(value)
  } else {
    return '0'
  }
}

export const NoData = 'No Data'

export const formatDateString = (date: string | undefined): string => {
  const formattedString = ''
  try {
    if (date) {
      return format(new Date(date || ''), 'MM/dd/yyyy')
    }
  } catch {
    return formattedString
  }
  return formattedString
}

export const filterNetFlowsByFilterData = (
  assetsList: IPlatFormFeeFlows[],
  filterBy: Dictionary<IDataFilter>
) => {
  let filteredData: IPlatFormFeeFlows[] = [...assetsList]
  if (filterBy) {
    Object.entries(filterBy)?.map(([key, data]) => {
      filteredData = filteredData.filter((x) => {
        if (data?.value) {
          return (data.value as (string | number)[]).includes(
            (x[key as keyof IPlatFormFeeFlows] as string | number) || NoData
          )
        } else {
          return true
        }
      })
    })
  }
  return filteredData
}

export const filterNetFlowsBySearchtext = (
  items: IPlatFormFeeFlows[],
  searchText: string
) => {
  let filtered: IPlatFormFeeFlows[] = items || []
  if (items && searchText && Array.isArray(items)) {
    const lowerCaseText = searchText.toLowerCase()
    filtered = items.filter((p) => {
      if (
        p?.accountNumber?.toLowerCase()?.includes(lowerCaseText) ||
        formatDateString(p?.flowDate?.toDateString())?.startsWith(searchText) ||
        p?.weight?.toString()?.includes(lowerCaseText) ||
        p?.numberOfDays?.toString()?.includes(lowerCaseText) ||
        p?.flowAmount?.toString()?.includes(lowerCaseText) ||
        p?.netFlow?.toString()?.startsWith(searchText)
      ) {
        return true
      } else {
        return false
      }
    })
  }
  return filtered
}

export const filterPlatFormCalcFilterData = (
  assetsList: IPlatFormFeeDetails[],
  filterBy: Dictionary<IDataFilter>
) => {
  let filteredData: IPlatFormFeeDetails[] = [...assetsList]
  if (filterBy) {
    Object.entries(filterBy)?.map(([key, data]) => {
      filteredData = filteredData.filter((x) => {
        if (data?.value) {
          return (data.value as (string | number)[]).includes(
            (x[key as keyof IPlatFormFeeDetails] as string | number) || NoData
          )
        } else {
          return true
        }
      })
    })
  }
  return filteredData
}

export const filterPlatFormCalcBySearchtext = (
  items: IPlatFormFeeDetails[],
  searchText: string
) => {
  let filtered: IPlatFormFeeFlows[] = items || []
  if (items && searchText && Array.isArray(items)) {
    const lowerCaseText = searchText.toLowerCase()
    filtered = items.filter((p) => {
      if (
        p?.accountNumber?.toLowerCase()?.includes(lowerCaseText) ||
        p?.envestnetAUM?.toString()?.includes(lowerCaseText) ||
        p?.netFlows?.toString()?.includes(lowerCaseText) ||
        p?.householdAUM?.toString()?.includes(lowerCaseText) ||
        p?.billingDays?.toString()?.includes(lowerCaseText) ||
        p?.annualDays?.toString()?.includes(lowerCaseText) ||
        p?.adjustedAUM?.toString()?.includes(lowerCaseText) ||
        p?.hhCap?.toString()?.includes(lowerCaseText) ||
        p?.feeRate?.toString()?.includes(lowerCaseText) ||
        p?.creditPct?.toString()?.includes(lowerCaseText) ||
        p?.netPct?.toString()?.includes(lowerCaseText) ||
        p?.billableAUM?.toString()?.includes(lowerCaseText) ||
        p?.apaFee?.toString()?.includes(lowerCaseText) ||
        p?.offCredit?.toString()?.includes(lowerCaseText) ||
        p?.netFee?.toString()?.includes(lowerCaseText) ||
        p?.prorated?.toString()?.includes(lowerCaseText)
      ) {
        return true
      } else {
        return false
      }
    })
  }
  return filtered
}
