import { TagDescription } from '@reduxjs/toolkit/dist/query/react'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { rdot360Api } from 'store/api/rdot360'
import { apiConstants } from './apis'
import {
  IRdot360ApiResponse,
  IActivitySummary,
  GetGainLossesIncomeSummaryResponse,
  IGroupedActivitySummary,
  ICheck,
  ICheckValue
} from './types'

type FinancialsApiTagType =
  | 'activity'
  | 'estimatedIncome'
  | 'projectedIncome'
  | 'income'
  | 'gainandlosss'
  | 'getActivityOverrideDesc'

const financialsApiTags: FinancialsApiTagType[] = [
  'activity',
  'estimatedIncome',
  'projectedIncome',
  'income',
  'gainandlosss',
  'getActivityOverrideDesc'
]

export interface UpdateAppendedDescriptionRequest {
  AccountNumber?: string
  Description?: string
  SequenceNo?: bigint
}
export interface UpdateAppendedDescriptionResponse {
  message?: string
  result?: string
  messageCode?: string
}
export interface IActivitySummaryAppendedDescription {
  description?: string
}

const { cacheTime } = apiConstants

const rdot360ApiEnhanced = rdot360Api.enhanceEndpoints({
  addTagTypes: financialsApiTags
})
export const financialsApi = rdot360ApiEnhanced.injectEndpoints({
  endpoints: (builder) => ({
    getRealizedGainLossSummary: builder.query<
      GetGainLossesIncomeSummaryResponse | undefined,
      {
        accounts: string[]
        financialYear: number
        taxable: boolean
      }
    >({
      query: ({ financialYear, taxable }) => {
        return {
          url: `holdings/odata/Position/GetRealizedGainLoss('A',${financialYear},'Y','',''${
            taxable ? `,'taxable'` : ''
          })`
        }
      },
      transformResponse: (
        response: IRdot360ApiResponse<GetGainLossesIncomeSummaryResponse>
      ): GetGainLossesIncomeSummaryResponse | undefined => response?.value?.[0],
      providesTags: ['rdot360', 'gainandlosss'],
      keepUnusedDataFor: cacheTime
    }),
    getRealizedGainLossSecurity: builder.query<
      GetGainLossesIncomeSummaryResponse | undefined,
      {
        accounts: string[]
        financialYear: number
        taxable: boolean
        viewBy?: 'A' | 'S'
      }
    >({
      query: ({ financialYear, taxable, viewBy }) => {
        return {
          url: `holdings/odata/Position/GetRealizedGainLoss('${
            viewBy || 'S'
          }',${financialYear},'Y','',''${taxable ? `,'taxable'` : ''})`
        }
      },
      transformResponse: (
        response: IRdot360ApiResponse<GetGainLossesIncomeSummaryResponse>
      ): GetGainLossesIncomeSummaryResponse | undefined => response?.value?.[0],
      providesTags: ['rdot360', 'gainandlosss'],
      keepUnusedDataFor: cacheTime
    }),
    getActivitySummary: builder.query<
      IActivitySummary,
      { accounts: string[]; asOfDate: string }
    >({
      query: () => {
        return {
          url: 'activityapi/odata/activitysummary?AccountList=&SearchBy=LatestActivity,10&DateRange=Last,30'
        }
      },
      transformResponse: (response: IRdot360ApiResponse<IActivitySummary>) =>
        response?.value?.[0] || {},
      providesTags: ['rdot360'],
      keepUnusedDataFor: cacheTime
    }),
    getDetailActivitySummary: builder.query<
      IActivitySummary,
      {
        categories?: string[]
        contextAccounts: string[]
        dateRange?: string
      }
    >({
      query: ({ categories, dateRange }) => {
        return {
          url: `activityapi/odata/activitysummary?AccountList=&DateRange=${dateRange}${
            categories && categories.length
              ? `&SearchBy=TranCode,${categories.join(',')}`
              : ''
          }`
        }
      },
      transformResponse: (response: IRdot360ApiResponse<IActivitySummary>) =>
        response?.value?.[0] || {},
      providesTags: ['rdot360', 'activity'],
      keepUnusedDataFor: cacheTime
    }),
    getActivitySummaryGroup: builder.query<
      IGroupedActivitySummary,
      { contextAccounts: string[]; range: string }
    >({
      query: ({ range }) => {
        return {
          url: `activityapi/odata/activitysummary/group?DateRange=${range}`
        }
      },
      transformResponse: (response: IGroupedActivitySummary[]) => response?.[0],
      providesTags: ['rdot360', 'activity'],
      keepUnusedDataFor: cacheTime
    }),
    retrieveCheck: builder.query<
      ICheckValue | undefined,
      {
        AccountList: string
        dateRange?: string
        FilterBy?: string
      }
    >({
      query: ({ AccountList, dateRange, FilterBy }) => ({
        url: `mm/odata/MMSummary?AccountList=${AccountList}&DateRange=${dateRange}&SearchBy=Image,Check,CKR&FilterBy=${FilterBy}`,
        method: 'GET'
      }),
      transformResponse: (response: ICheck | undefined) => response?.value?.[0],
      keepUnusedDataFor: cacheTime
    }),
    GetRealizedGainLossPossibleYears: builder.query<
      number[] | undefined,
      { accounts: string[] }
    >({
      query: () => {
        return {
          url: `holdings/odata/Position/GetRealizedGainLossPossibleYears(true)`
        }
      },
      transformResponse: (response: number[] | undefined) => response || [],
      providesTags: ['rdot360', 'gainandlosss'],
      keepUnusedDataFor: cacheTime
    }),
    getActivitySummaryOverrideDesc: builder.query<
      IActivitySummaryAppendedDescription,
      { contextAccounts: string[]; id: string }
    >({
      query: ({ id }) => {
        return {
          url: `activityapi/odata/ActivitySummary/getActivityOverrideDesc?activityId=${id}`
        }
      },
      transformResponse: (response: IActivitySummaryAppendedDescription) =>
        response,
      providesTags: (_, _1, req) => [
        'rdot360',
        { type: 'getActivityOverrideDesc', id: req.id }
      ],
      keepUnusedDataFor: cacheTime
    }),
    rdot360_updateActivityDescription: builder.mutation<
      UpdateAppendedDescriptionResponse,
      UpdateAppendedDescriptionRequest
    >({
      query: ({ AccountNumber, Description, SequenceNo }) => ({
        url: `/activityapi/odata/ActivitySummary/updateDescription`,
        method: 'POST',
        data: {
          AccountNumber: AccountNumber,
          Description: Description,
          NfsSeqId: SequenceNo
        }
      }),
      invalidatesTags: (_, _1, req) => [
        {
          type: 'getActivityOverrideDesc',
          id: req.SequenceNo?.toString() || ''
        }
      ]
    })
  })
})
export const {
  useGetRealizedGainLossSummaryQuery,
  useGetRealizedGainLossSecurityQuery,
  useGetActivitySummaryQuery,
  useGetDetailActivitySummaryQuery,
  useGetActivitySummaryGroupQuery,
  useRetrieveCheckQuery,
  useLazyRetrieveCheckQuery,
  useGetRealizedGainLossPossibleYearsQuery,
  useGetActivitySummaryOverrideDescQuery,
  useRdot360_updateActivityDescriptionMutation
} = financialsApi

export const useFinancialsApiUtil = () => {
  const dispatch = useDispatch()
  const invalidateTags = useCallback(
    (tags: TagDescription<FinancialsApiTagType>[]) =>
      dispatch(financialsApi.util.invalidateTags(tags)),
    [dispatch]
  )

  return {
    invalidateTags
  }
}
