import { IOdataListColumnDefinition } from 'features/OdataList/common/types'

export type ActivityColumnName =
  | 'Activity Date'
  | 'Account Number'
  | 'Advisor ID'
  | 'Amount'
  | 'Description'
  | 'Household Id'
  | 'Household Name'
  | 'Party Name'
  | 'Party Status'
  | 'Party Type'
  | 'Price'
  | 'Quantity'
  | 'Symbol / CUSIP'
  | 'Team Name'
  | 'Type'

export interface IActivityColumnDefinition extends IOdataListColumnDefinition {
  name: ActivityColumnName
}

export const ActivityColumnDefinitions: IActivityColumnDefinition[] = [
  {
    name: 'Activity Date',
    dataPath: 'tradeDate',
    type: 'date-only',
    width: 140,
    removable: true,
    sortable: true,
    filterable: true,
    select: ['settleDate']
  },
  {
    name: 'Account Number',
    dataPath: 'accountNumber',
    filterable: true,
    removable: true,
    searchFields: ['accountNumber'],
    select: [
      'accountId',
      'accountCategoryCode',
      'accountNickname',
      'custodyAccount',
      'householdId',
      'registeredRep',
      'registrationTypeCode'
    ],
    sortable: true,
    type: 'string',
    width: 195
  },
  {
    name: 'Advisor ID',
    dataPath: 'registeredRep',
    type: 'string',
    width: 60,
    facetable: true,
    filterable: true,
    removable: true,
    sortable: true
  },
  {
    name: 'Amount',
    dataPath: 'marketValue',
    filterable: true,
    removable: true,
    sortable: true,
    type: 'number',
    width: 125
  },
  {
    name: 'Description',
    dataPath: 'tranDesc',
    filterable: true,
    type: 'string',
    width: 500,
    removable: true,
    sortable: true,
    searchFields: ['tranDesc']
  },
  {
    name: 'Household Id',
    dataPath: 'householdId',
    type: 'string',
    width: 120,
    removable: true,
    sortable: true
  },
  {
    name: 'Household Name',
    dataPath: 'householdName',
    filterable: true,
    removable: true,
    searchFields: ['householdName'],
    sortable: true,
    type: 'string',
    width: 220
  },
  {
    name: 'Party Name',
    dataPath: 'partyName',
    type: 'string',
    width: 180,
    removable: true,
    sortable: true
  },
  {
    name: 'Party Status',
    dataPath: 'partyStatus',
    type: 'string',
    width: 100,
    removable: true,
    sortable: true
  },
  {
    name: 'Party Type',
    dataPath: 'partyType',
    type: 'string',
    width: 50,
    removable: true,
    sortable: true
  },
  {
    name: 'Price',
    dataPath: 'price',
    type: 'number',
    width: 125,
    removable: true,
    sortable: true
  },
  {
    name: 'Quantity',
    dataPath: 'quantity',
    type: 'number',
    width: 125,
    removable: true,
    sortable: true
  },
  {
    name: 'Symbol / CUSIP',
    dataPath: 'symbol',
    filterable: true,
    removable: true,
    searchFields: ['cusip', 'symbol'],
    select: ['cusip', 'symbol'],
    sortable: true,
    type: 'string',
    width: 140
  },
  {
    name: 'Team Name',
    dataPath: 'teamName',
    filterable: true,
    removable: true,
    sortable: true,
    type: 'string',
    width: 180
  },
  {
    name: 'Type',
    dataPath: 'rcmCatDesc',
    type: 'string',
    width: 180,
    facetable: true,
    filterable: true,
    removable: true,
    sortable: true
  }
]
