import { SortingState } from '@tanstack/react-table'

export interface WidgetTableProps<T> {
  data?: T[]
  columns: any
  sort?: SortingState
  isUninitialized?: boolean
  isLoading?: boolean
  hideHeader?: boolean
  isFooter?: boolean
  emptyRowCount?: number
}

export const USDCell: React.FC<{
  value?: string | number
  fractionDigits?: number
  defaultValue?: string
  isCenterTextAlign?: boolean
}> = ({ value, defaultValue = '--', fractionDigits, isCenterTextAlign }) => {
  const decimals = fractionDigits != null ? fractionDigits : value === 0 ? 0 : 2
  const totalAmount =
    value !== undefined && value !== null
      ? parseFloat(value.toString()) < 0
        ? '(' +
          Math.abs(parseFloat(value.toString()))?.toLocaleString('en-US', {
            style: 'currency',
            currencySign: 'standard',
            maximumFractionDigits: 0,
            currency: 'USD'
          }) +
          ')'
        : Math.abs(parseFloat(value.toString()))?.toLocaleString('en-US', {
            style: 'currency',
            currencySign: 'standard',
            maximumFractionDigits: 0,
            currency: 'USD'
          })
      : defaultValue
  fractionDigits = decimals

  const totalAmountTitle = value?.toLocaleString('en-US', {
    style: 'currency',
    currencySign: 'standard',
    maximumFractionDigits: 2,
    currency: 'USD'
  })
  return (
    (
      <div
        css={[
          {
            textAlign: isCenterTextAlign ? 'center' : 'right'
          }
        ]}
        title={totalAmountTitle}
      >
        {totalAmount}
      </div>
    ) || <div style={{ textAlign: 'center' }}>--</div>
  )
}
export const USDCellCompact: React.FC<{
  value?: string | number
  fractionDigits?: number
  defaultValue?: string
}> = ({ value, defaultValue = '--', fractionDigits }) => {
  const decimals = fractionDigits != null ? fractionDigits : value === 0 ? 0 : 2

  const totalAmount =
    value !== undefined && value !== null
      ? value?.toLocaleString('en-US', {
          style: 'currency',
          currencySign: 'standard',
          notation: 'compact',
          maximumFractionDigits: 1,
          currency: 'USD'
        })
      : defaultValue
  fractionDigits = decimals

  const totalAmountTitle = value?.toLocaleString('en-US', {
    style: 'currency',
    currencySign: 'standard',
    maximumFractionDigits: 2,
    currency: 'USD'
  })
  return (
    (
      <span
        css={[
          {
            textAlign: 'end'
          }
        ]}
        title={totalAmountTitle}
      >
        {totalAmount}
      </span>
    ) || <span>--</span>
  )
}

export const USNumberCell: React.FC<{
  value?: string | number
  defaultValue?: string
}> = ({ value, defaultValue = '--' }) => {
  const totalAmount =
    value !== undefined && value !== null
      ? value?.toLocaleString('en-US', {
          style: 'currency',
          currencySign: 'standard',
          maximumFractionDigits: 0,
          currency: 'USD'
        })
      : defaultValue

  const totalAmountTitle = value?.toLocaleString('en-US', {
    style: 'currency',
    currencySign: 'standard',
    maximumFractionDigits: 0,
    currency: 'USD'
  })
  return (
    (
      <span
        css={[
          {
            textAlign: 'end'
          }
        ]}
        title={totalAmountTitle?.replace('$', '')}
      >
        {totalAmount?.replace('$', '')}
      </span>
    ) || <span>--</span>
  )
}
