import type { AccountInfo, AuthError } from '@azure/msal-browser'
import { replace } from 'connected-react-router'
import { jwtDecode } from 'jwt-decode'
import { flow } from 'lodash/fp'
import { call, put, select, takeLatest } from 'typed-redux-saga'
import { ActionType, createAction, createReducer } from 'typesafe-actions'
import { AppState } from '..'
import { makeArrayFromString } from '../../shared'
import * as auth from '../../shared/services/auth'
import { IEnvironmentApiConfiguration } from '../../shared/services/environment/IEnvironmentConfiguration'
import {
  setAuthenticatedUserContext,
  trackEvent
} from '../../shared/services/telemetry'
import { tryAcquireAccessToken } from '../shared/sagas'
import { getRockefellerApiConfig } from '../system'

const LOGIN_REQUESTED = '@user/LOGIN_REQUESTED'
const LOGIN_INTERACTIVE = '@user/LOGIN_INTERACTIVE'
const LOGIN_SUCCESS = '@user/LOGIN_SUCCESS'
const LOGIN_FAILURE = '@user/LOGIN_FAILURE'
const LOGIN_RESET = '@user/LOGIN_RESET'
const SWITCH_ACCOUNT = '@user/SWITCH_ACCOUNT'
const LOGOUT_REQUESTED = '@user/LOGOUT_REQUESTED'
const LOGOUT_SUCCESS = '@user/LOGOUT_SUCCESS'

export interface ILoginSuccessPayload {
  account: AccountInfo
  token: Record<string, string>
}

export interface ILoginPayload {
  destinationAfterRedirect?: string
  loginHint?: string
  prompt?: auth.PromptTypes
  loginType?: auth.LoginTypes
}

export const rdotUserActions = {
  loginRequested: createAction(LOGIN_REQUESTED)<ILoginPayload>(),
  loginInteractive: createAction(LOGIN_INTERACTIVE)<ILoginPayload>(),
  loginSuccess: createAction(LOGIN_SUCCESS)<ILoginSuccessPayload>(),
  loginFailure: createAction(LOGIN_FAILURE)<AuthError>(),
  switchAccount: createAction(SWITCH_ACCOUNT)(),
  reset: createAction(LOGIN_RESET)(),
  logoutRequested: createAction(LOGOUT_REQUESTED)(),
  logoutSuccess: createAction(LOGOUT_SUCCESS)()
}

export type RdotUserActionTypes = ActionType<typeof rdotUserActions>

export enum RdotUserRoleEnum {
  Accounting_Clients = 'Accounting.Clients',
  Accounting_Trusts = 'Accounting.Trusts',
  Accounting_AccountManager = 'Accounting.AccountManager',
  Finance_FeeAdmin = 'Finance.FeeAdmin',
  Finance_Read = 'Finance.Read',
  Advisory_User = 'Advisory.User',
  Advisory_FinancialAdvisor = 'Advisory.FinancialAdvisor',
  Advisory_JuniorAdvisor = 'Advisory.JuniorAdvisor',
  Advisory_SeniorCA = 'Advisory.SeniorCA',
  Compliance_Reviewer = 'Compliance.Reviewer',
  Compliance_Admin = 'Compliance.Admin',
  Operations_Cash = 'Operations.Cash',
  Operations_Admin = 'Operations.Admin',
  Operations_Investments = 'Operations.Investments',
  Advisory_Pilot = 'Advisory.Pilot',
  IEX_User = 'IEX.User',
  Advisory_OLT = 'Advisory.OLT',
  Products_Retirements = 'Products.Retirements',
  Products_AltInvestments = 'Products.AltInvestments',
  Products_AltInvestments_Approve = 'Products.AltInvestments.Approve',
  Products_InsAnnuities = 'Products.InsAnnuities',
  ClientOnboarding_Admin = 'ClientOnboarding.Admin',
  ClientOnboarding_Viewer = 'ClientOnboarding.Viewer',
  Operations_RockitAdmin = 'Operations.RockitAdmin',
  Advisory_HurdlesAdmin = 'Advisory.Hurdles.Admin',
  Advisory_HurdlesReadWithinHierarchy = 'Advisory.Hurdles.ReadWithinHierarchy',
  Advisory_Feature_BookDashboardActivity = 'Advisory.Feature.BookDashboardActivity',
  Advisory_Feature_OptionsIncomeAndExpenseReport = 'Advisory.Feature.OptionsIncomeAndExpenseReport',
  Advisory_Feature_BoardMemberAccountsReport = 'Advisory.Feature.BoardMemberAccountsReport',
  Advisory_Feature_NextGenCouncilReport = 'Advisory.Feature.NextGenCouncilReport',
  Advisory_Feature_AIDashboard = 'Advisory.Feature.AIDashboard',
  Advisory_Feature_Rdot360 = 'Advisory.Feature.Rdot360',
  Advisory_Feature_RelationhipView = 'Advisory.Feature.RelationshipView',
  Advisory_HomeOfficeUser = 'Advisory.HomeOfficeUser',
  Advisory_Feature_LoansDashboard = 'Advisory.Feature.LoansDashboard',
  Admin_Role_ADUserAdmin = 'Admin.Role.ADUserAdmin',
  HomeOffice_SecOverride_ViewOnly = 'HomeOffice.SecOverride.ViewOnly',
  StructuredProducts_SecOverride_Create = 'StructuredProducts.SecOverride.Create',
  HomeOffice_SecOverride_Create = 'HomeOffice.SecOverride.Create',
  Legal_AltInvestments = 'Legal.AltInvestments',
  Operations_AltInvestments = 'Operations.AltInvestments',
  Products_AltInvestments_Create = 'Products.AltInvestments.Create',
  Advisory_Feature_RockDigital = 'Advisory.Feature.RockDigital',
  Advisory_Feature_ClientDashboardInsights = 'Advisory.Feature.ClientDashboardInsights',
  Advisory_Feature_ClientDashboardRevenue = 'Advisory.Feature.ClientDashboardRevenue',
  Advisory_Feature_CommunicationsCenter = 'Advisory.Feature.CommunicationsCenter',
  Advisory_Feature_PostSplitRevenue = 'Advisory.Feature.PostSplitRevenue',
  Advisory_ClientAssociate = 'Advisory.ClientAssociate',
  Advisory_ThirdParty = 'Advisory.ThirdParty',
  Advisory_AIRevenue = 'Advisory.AIRevenue',
  Advisory_AIRevenue_Partial = 'Advisory.AIRevenue.Partial',
  Advisory_Feature_AIDashboardTransfers = 'Advisory.Feature.AIDashboardTransfers',
  HomeOffice_ThirdParty = 'HomeOffice.ThirdParty',
  Advisory_Feature_ClientVerification = 'Advisory.Feature.ClientVerification',
  DueDiligence_View = 'DueDiligence.View',
  DueDiligence_Approve = 'DueDiligence.Approve',
  PlatformFees_View = 'PlatformFees.View',
  PlatformFees_Operations = 'PlatformFees.Operations',
  PlatformFees_FeeCalc = 'PlatformFees.FeeCalc'
}

export interface IRdotUser {
  username?: string
  name?: string
  poolIds?: string[]
  primaryId?: string
  wsid?: string
  roles?: RdotUserRoleEnum[]
  oid?: string
}

export interface IUserState {
  error?: AuthError
  item?: IRdotUser
}

const initialState: IUserState = {}

export const rdotUserReducer = createReducer<IUserState, RdotUserActionTypes>(
  initialState
)
  .handleAction(rdotUserActions.loginRequested, () => ({
    ...initialState
  }))
  .handleAction(rdotUserActions.loginSuccess, (state, action) => {
    const { account, token } = action.payload
    const rdotUser: IRdotUser = {
      username: account.username,
      name: token.name,
      poolIds: makeArrayFromString(token.poolIds || ''),
      roles: (makeArrayFromString(token.roles) || []) as RdotUserRoleEnum[],
      oid: token.oid,
      primaryId: token.primaryid,
      wsid: token.wsid
    }
    return {
      ...state,
      item: rdotUser
    }
  })
  .handleAction(rdotUserActions.loginFailure, (_, action) => ({
    ...initialState,
    error: action.payload
  }))

export const getRdotUserState = (state: AppState) => state.user.rdot
export const getRdotUser = flow(getRdotUserState, (x) => x.item)

const reset = function* () {
  yield call(auth.reset)
}

const loginInteractive = function* (
  action: ReturnType<typeof rdotUserActions.loginInteractive>
) {
  const rockApiConfig: IEnvironmentApiConfiguration = yield select(
    getRockefellerApiConfig
  )

  yield call(auth.login, action.payload.loginType || 'redirect', {
    loginHint: action.payload.loginHint,
    prompt: action.payload.prompt,
    state: action.payload.destinationAfterRedirect,
    scopes: [rockApiConfig.scopes]
  })
}

function* logout() {
  yield* call(auth.logout)
}

function* login(action: ReturnType<typeof rdotUserActions.loginRequested>) {
  const account = yield* call(auth.getAccount)

  const destinationAfterRedirect =
    (yield* call(auth.getState)) || action.payload.destinationAfterRedirect

  const redirectError = yield* call(auth.getRedirectError)

  try {
    if (redirectError) {
      throw redirectError
    }

    if (!account) {
      console.debug('user saga: before login interactive')
      yield put(rdotUserActions.loginInteractive({ destinationAfterRedirect }))
      return
    }

    setAuthenticatedUserContext(account.username, account.homeAccountId)

    const apiConfig: IEnvironmentApiConfiguration = yield select(
      getRockefellerApiConfig
    )

    const token = yield* call(
      tryAcquireAccessToken,
      apiConfig.scopes,
      destinationAfterRedirect,
      account.username,
      'redirect' as const
    )
    const decodedToken = jwtDecode<any>(token) || {}

    console.debug('user saga: acquire token success', decodedToken)

    yield call(trackEvent, 'Login Success', {
      username: decodedToken.preferred_username,
      wsid: decodedToken.wsid,
      primaryId: decodedToken.primaryid,
      poolIds: decodedToken.poolIds
    })

    yield put(rdotUserActions.loginSuccess({ account, token: decodedToken }))

    if (destinationAfterRedirect) {
      yield put(replace(destinationAfterRedirect))
    }
  } catch (error: any) {
    console.debug(
      'user saga: login error',
      error && error.message ? error.message : 'no error message'
    )
    const requiresInteraction: boolean = yield call(
      auth.errorRequiresInteraction,
      error
    )
    if (requiresInteraction) {
      yield put(
        rdotUserActions.loginInteractive({
          destinationAfterRedirect,
          prompt: 'select_account',
          loginHint: ''
        })
      )
    } else {
      console.debug('user saga: login failue')
      yield put(rdotUserActions.loginFailure(error))
    }
  }
}

export const rdotUserSagas = [
  () => takeLatest(rdotUserActions.loginRequested, login),
  () => takeLatest(rdotUserActions.loginInteractive, loginInteractive),
  () => takeLatest(rdotUserActions.logoutRequested, logout),
  () =>
    takeLatest(rdotUserActions.switchAccount, function* () {
      yield put(
        rdotUserActions.loginInteractive({
          loginHint: '',
          prompt: 'select_account',
          loginType: 'redirect'
        })
      )
    }),
  () =>
    takeLatest(rdotUserActions.reset, function* () {
      yield call(reset)
    })
]
