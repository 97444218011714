import { IODataListState } from 'features/OdataList/store/odataListWithFacetsStore'
import { lazy } from 'react'
import { combineReducers, Reducer } from 'redux'
import { IAsyncReducerState } from 'store/shared'
import { IModuleDefinition } from '../../../types'
import {
  retirementHoldingsExportReducer,
  retirementHoldingsExportSagas
} from './modules/Holdings/export'
import {
  reducer as retirementHoldingsReducer,
  sagas as retirementHoldingsSagas
} from './modules/Holdings/store'
import {
  retirementPlansExportReducer,
  retirementPlansExportSagas
} from './modules/Plans/export'
import {
  reducer as retirementPlansReducer,
  sagas as retirementPlansSagas
} from './modules/Plans/store'
import { IRetirementAsset, IRetirementFund } from './shared/retirementTypes'

const RetirementContainer = lazy(() => import('./RetirementContainer'))

const moduleDefinition: IModuleDefinition = {
  routeProps: {
    path: '/retirement/*',
    private: true,
    component: RetirementContainer
  },
  name: 'Retirement'
}

export default moduleDefinition

interface IRetirementReducer {
  holdings: IODataListState<IRetirementAsset>
  holdingsExport: IAsyncReducerState<unknown, unknown>
  plans: IODataListState<IRetirementFund>
  plansExport: IAsyncReducerState<unknown, unknown>
}

export const retirementReducer: Reducer<IRetirementReducer> = combineReducers({
  holdings: retirementHoldingsReducer,
  holdingsExport: retirementHoldingsExportReducer,
  plans: retirementPlansReducer,
  plansExport: retirementPlansExportReducer
})

export const retirementSagas = [
  ...retirementHoldingsSagas,
  ...retirementHoldingsExportSagas,
  ...retirementPlansSagas,
  ...retirementPlansExportSagas
]
