import {
  DirectionalHint,
  HoverCard,
  HoverCardType,
  Icon,
  IPlainCardProps,
  makeStyles,
  Stack,
  Text,
  TooltipHost
} from '@fluentui/react'
import React, { memo, PropsWithChildren, useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  IManagedAccountFeeDetail,
  IManagedAccountFeeSummary
} from '../../api/account.types'
import { LoadingComponent } from '../../shared/components/Loading'
import { Separator } from '../../shared/components/Separator'
import { PercentCell } from '../Lists/core/components/PercentCell'
import {
  feeDetailsActions,
  getFeeDetailsResult,
  getIsFeeDetailsLoading
} from './store/feeDetails'

const useIconStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.themePrimary
  }
}))

export const TooltipDisclaimer: React.FC<{ content: string | JSX.Element }> = ({
  content
}) => {
  const classes = useIconStyles()
  return (
    <TooltipHost content={content}>
      <Icon
        className={classes.icon}
        iconName="info"
        styles={{ root: { cursor: 'pointer' } }}
      />
    </TooltipHost>
  )
}

interface IFeeDetailsProps {
  loading?: boolean
  feeSummary?: IManagedAccountFeeSummary
  feeDetails?: IManagedAccountFeeDetail[]
  accountNumber?: string
}

const FeeDetails: React.FC<IFeeDetailsProps> = memo(
  ({ loading, feeSummary }) => {
    const { clientFeePercent, managerFeePercent } = feeSummary || {}

    const parts = {
      'Manager Fee Percent': <PercentCell value={managerFeePercent} />,
      'Client Fee Percent': <PercentCell value={clientFeePercent} />
    }

    return (
      <Stack tokens={{ childrenGap: 5, padding: 20 }}>
        {!loading && (
          <Stack.Item>
            <div style={{ textAlign: 'center' }}>
              <Text styles={{ root: { fontWeight: 'bold' } }}>
                Managed Account Fee Summary
              </Text>
            </div>
            <Separator styles={{ root: { padding: 0 } }} />
          </Stack.Item>
        )}
        {loading && <LoadingComponent />}
        {!loading && (
          <table>
            <tbody>
              {Object.entries(parts)
                .filter(([, value]) => value)
                .map(([key, value]) => (
                  <tr key={key}>
                    <td style={{ textAlign: 'right', paddingRight: '10px' }}>
                      <b>{key}:</b>
                    </td>
                    <td>{value}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        )}
      </Stack>
    )
  }
)

export const ManagedAccountFeeHoverCard: React.FC<
  PropsWithChildren<{
    accountId: string
  }>
> = ({ accountId, children }) => {
  const dispatch = useDispatch()
  const details = useSelector(getFeeDetailsResult)
  const loading = useSelector(getIsFeeDetailsLoading)
  const plainCardProps = useMemo(
    (): IPlainCardProps => ({
      onRenderPlainCard: () => (
        <FeeDetails
          loading={loading}
          feeSummary={{
            ...details?.feeSummary,
            cashAvailable: details?.AccountKPIs?.cashAvlToWithDraw
          }}
          feeDetails={details?.feeDetails}
          accountNumber={details?.CustodyAccount}
        />
      ),
      directionalHint: DirectionalHint.rightCenter,
      calloutProps: {
        isBeakVisible: true
      }
    }),
    [details, loading]
  )

  const onCardVisible = useCallback(() => {
    dispatch(feeDetailsActions.request(accountId))
  }, [accountId, dispatch])

  return (
    <HoverCard
      plainCardProps={plainCardProps}
      instantOpenOnClick={true}
      type={HoverCardType.plain}
      onCardVisible={onCardVisible}
    >
      {children}
    </HoverCard>
  )
}
