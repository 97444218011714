import { IOdataRequest } from 'api/odata.types'
import { convertColumnTypeToFilterType } from 'features/DataList/service'
import { IListsFilter } from 'features/Lists/core/contracts/IListsFilter'
import {
  createOdataListWithFacetsStore,
  IODataListState
} from 'features/OdataList/store/odataListWithFacetsStore'
import { keyBy } from 'lodash'
import { Reducer } from 'redux'
import { IApiOptions } from 'shared/contracts/IApiOptions'
import { AppState } from 'store'
import { call, cancelled } from 'typed-redux-saga'
import { fetchOdataEndpoint, getDatahubApiOptions } from '../../../shared'
import { IPlatformFeeHistoryModel } from '../../api'
import { platformFeeHistoryListColumns } from './platformFeeHistoryColumns'

export const fetchPlatformFeeHistory = (
  options: IApiOptions,
  request: IOdataRequest
) => {
  const path = 'datahub/PlatformFeeHistory'
  return fetchOdataEndpoint<IPlatformFeeHistoryModel>(
    { ...options, path },
    request
  )
}

export const saga_fetchPlatformFeeHistory = function* (request: IOdataRequest) {
  const [apiOptions, cancelTokenSource] = yield* call(getDatahubApiOptions)
  try {
    return yield* call(fetchPlatformFeeHistory, apiOptions, request)
  } finally {
    if (yield* cancelled()) {
      cancelTokenSource.cancel()
    }
  }
}

const rootSelector = (state: AppState) =>
  state.modules.advisory.modules.fees.modules.platformFeeHistory
    .platformFeeHistoryList

const uiFilters = keyBy(
  platformFeeHistoryListColumns
    .filter((x) => x.filterable)
    .map((column): IListsFilter => {
      const base = {
        id: column.name,
        name: column.name,
        type: convertColumnTypeToFilterType(column),
        dataPath: column.dataPath,
        hasValue: false
      }

      return base
    }),
  ({ id }) => id
)

const selectedColumnIds = platformFeeHistoryListColumns.map((x) => x.name)

const store = createOdataListWithFacetsStore({
  prefix: `@modules/@advisory/@modules/@fees/@modules/@platformFeeHistory/@platformFeeHistoryList`,
  getOdataResults: saga_fetchPlatformFeeHistory,
  initialState: {
    data: {},
    ui: {
      columns: platformFeeHistoryListColumns,
      selectedColumns: selectedColumnIds,
      filters: uiFilters,
      sortBy: {
        direction: 'desc',
        name: 'Bill Date'
      }
    },
    facets: {}
  },
  rootSelector
})

export const platformFeeHistoryListReducer = store.reducer as Reducer<
  IODataListState<IPlatformFeeHistoryModel>
>
export const platformFeeHistoryListSagas = store.sagas
export const {
  uiActions: platformFeeHistoryListUiActions,
  facetActions: platformFeeHistoryListFacetActions
} = store.actions
export const {
  uiSelectors: platformFeeHistoryListUiSelectors,
  facetSelectors: platformFeeHistoryListFacetSelectors
} = store.selectors
