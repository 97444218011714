import {
  DirectionalHint,
  HoverCard,
  HoverCardType,
  IPlainCardProps
} from '@fluentui/react'
import { PropsWithChildren, useMemo } from 'react'
import { IFeeDetailRequest } from '../../../api'
import { FeesFromOtherAccounts } from './FeesFromOtherAccounts'

export const FeesFromOtherAccountsHover: React.FC<
  PropsWithChildren<Partial<IFeeDetailRequest>>
> = ({ children, ...rest }) => {
  const hoverCardProps = useMemo(
    (): IPlainCardProps => ({
      onRenderPlainCard: () => <FeesFromOtherAccounts {...rest} />,
      directionalHint: DirectionalHint.leftCenter
    }),
    [rest]
  )

  return (
    <HoverCard
      plainCardProps={hoverCardProps}
      cardOpenDelay={0}
      instantOpenOnClick={false}
      type={HoverCardType.plain}
    >
      {children}
    </HoverCard>
  )
}
