import { ITheme, makeStyles, SearchBox, Stack, Text } from '@fluentui/react'
import { debounce } from 'lodash'
import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { USD } from '../../../../shared/components/Formatting'
import { chartColors } from '../../../../shared/services/theme'
import { getCurrentHousehold, householdDetailUiActions } from './store'

const useClasses = makeStyles((theme: ITheme) => {
  const headerItem = {
    backgroundColor: theme.palette.neutralLighterAlt,
    border: `solid 1px ${theme.palette.neutralLighter}`,
    borderBottom: `solid 3px ${chartColors[1]}`,
    padding: '3px 5px'
  }

  const actionableHeaderItem = {
    ...headerItem,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.neutralLighter
    }
  }

  const linkButton = {
    color: theme.semanticColors.link,
    '&:hover': { color: theme.semanticColors.linkHovered }
  }

  return {
    headerItem,
    actionableHeaderItem,
    linkButton
  }
})

export const HouseholdHeader = () => {
  const household = useSelector(getCurrentHousehold)
  const classes = useClasses()
  const dispatch = useDispatch()
  const debouncedOnChange = useMemo(
    () =>
      debounce((_?: any, newValue?: string) => {
        dispatch(householdDetailUiActions.updateFilter(newValue || ''))
      }, 200),
    [dispatch]
  )
  const onFilterChanged = useCallback(debouncedOnChange, [debouncedOnChange])

  if (!household) {
    return null
  }

  return (
    <Stack tokens={{ childrenGap: 15 }}>
      <Stack horizontal={true} tokens={{ childrenGap: 10 }} wrap={true}>
        <Stack className={classes.headerItem}>
          <Text variant="small">Household Name</Text>
          <Text
            variant="mediumPlus"
            styles={{ root: { fontWeight: 'bold' } }}
            nowrap={true}
          >
            {household?.householdName}
          </Text>
        </Stack>

        <Stack className={classes.headerItem}>
          <Text variant="small">AUS</Text>
          <Text variant="mediumPlus" styles={{ root: { fontWeight: 'bold' } }}>
            <USD
              value={household?.householdKPI?.AumTotal || 0}
              fractionDigits={0}
            />
          </Text>
        </Stack>

        <Stack className={classes.headerItem}>
          <Text variant="small">T12 Revenue</Text>
          <Text variant="mediumPlus" styles={{ root: { fontWeight: 'bold' } }}>
            <USD
              value={household?.revenue?.ttmrevenue || 0}
              fractionDigits={0}
            />
          </Text>
        </Stack>
      </Stack>
      <Stack.Item>
        <SearchBox
          placeholder="Filter Clients and Accounts"
          onChange={onFilterChanged}
        />
      </Stack.Item>
    </Stack>
  )
}
