import { useTheme } from '@fluentui/react'
import { skipToken } from '@reduxjs/toolkit/query'
import { DateOnlyCell } from 'features/Lists/core/components/DateCell'
import { USDCell } from 'features/Lists/core/components/USDCell'
import { sumBy } from 'lodash'
import { LoadingComponent } from 'shared/components/Loading'
import { ConnectedMaskedText } from 'shared/components/MaskedText'
import {
  IFeeDetailRequest,
  useGetManagedAccountFeeDetailsQuery
} from '../../../api'

export const PendingClientFees: React.FC<Partial<IFeeDetailRequest>> = ({
  accountNumber,
  billDate
}) => {
  const { data, isFetching } = useGetManagedAccountFeeDetailsQuery(
    accountNumber && billDate ? { accountNumber, billDate } : skipToken
  )

  const theme = useTheme()

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: 15,
        padding: '20px'
      }}
    >
      <div style={{ display: 'flex', gap: 5, alignItems: 'end' }}>
        <span>Account:</span>
        <ConnectedMaskedText text={data?.[0]?.account} />
      </div>
      {isFetching && <LoadingComponent />}
      {!isFetching && (
        <table
          css={{
            padding: '10px',
            '& th, & td': {
              padding: '1px 5px',
              textAlign: 'right',
              borderCollapse: 'collapse'
            }
          }}
        >
          <thead>
            <tr>
              <th>Managed Assets</th>
              <th>Manager Fee</th>
              <th>Rockefeller Fee</th>
              <th>Client Fee</th>
              <th style={{ textAlign: 'left' }}>Bill Type</th>
              <th>Period Start</th>
              <th>Period End</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((detail, index) => (
              <tr key={index}>
                <td>
                  <USDCell value={detail.billableValue} />
                </td>
                <td>
                  <USDCell value={detail.managerFee} />
                </td>
                <td>
                  <USDCell value={detail.rockefellerFee} />
                </td>
                <td>
                  <USDCell value={detail.clientFee} />
                </td>
                <td style={{ textAlign: 'left' }}>{detail.billType}</td>
                <td>
                  <DateOnlyCell value={detail.billPeriodStart} />
                </td>
                <td>
                  <DateOnlyCell value={detail.billPeriodEnd} />
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr
              css={{
                fontWeight: 'bold',
                '& td': { borderTop: `solid 1px ${theme.palette.black}` }
              }}
            >
              <td />
              <td>
                <USDCell
                  value={sumBy(data, ({ managerFee }) => managerFee || 0)}
                />
              </td>
              <td>
                <USDCell
                  value={sumBy(
                    data,
                    ({ rockefellerFee }) => rockefellerFee || 0
                  )}
                />
              </td>
              <td>
                <USDCell
                  value={sumBy(data, ({ clientFee }) => clientFee || 0)}
                />
              </td>
              <td />
              <td />
              <td />
            </tr>
          </tfoot>
        </table>
      )}
    </div>
  )
}
