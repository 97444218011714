import { IOdataListColumnDefinition } from 'features/OdataList/common/types'

export type RetirementPlanColumnName =
  | '# of Participants'
  | 'Advisor'
  | 'Advisor ID'
  | 'Advisor Team'
  | 'Client Live Date'
  | 'Company Name'
  | 'Contract Number'
  | 'Division'
  | 'Fiduciary Status'
  | 'Market Value'
  | 'Plan Fee Amount'
  | 'Plan Name'
  | 'Plan Provider'
  | 'Plan Rate'
  | 'Plan Type'
  | 'RCM Client Type'
  | 'Valuation Date'

export interface IRetirementPlanColumnDefinition
  extends IOdataListColumnDefinition {
  name: RetirementPlanColumnName
}

export const retirementPlanColumns: IRetirementPlanColumnDefinition[] = [
  {
    name: '# of Participants',
    dataPath: 'participants',
    filterable: true,
    select: ['participants'],
    sortable: true,
    type: 'number',
    width: 100
  },
  {
    name: 'Advisor ID',
    collectionPath: 'retirementPlan',
    dataPath: 'retirementPlan/repId',
    expand: ['RetirementPlan($select=repId)'],
    filterable: true,
    type: 'string',
    width: 60
  },
  {
    name: 'Contract Number',
    dataPath: 'contractNumber',
    filterable: true,
    searchFields: ['contractNumber'],
    select: ['contractNumber'],
    sortable: true,
    type: 'string',
    width: 100
  },
  {
    name: 'Company Name',
    dataPath: 'companyName',
    filterable: true,
    searchFields: ['companyName'],
    select: ['companyName'],
    sortable: true,
    type: 'string',
    width: 250
  },
  {
    name: 'Plan Name',
    dataPath: 'planName',
    filterable: true,
    searchFields: ['planName'],
    select: ['planName'],
    sortable: true,
    type: 'string',
    width: 320
  },
  {
    name: 'Plan Provider',
    collectionPath: 'retirementPlan',
    dataPath: 'retirementPlan/planProvider',
    expand: ['RetirementPlan($select=planProvider)'],
    facetable: true,
    filterable: true,
    searchFields: ['retirementPlan/planProvider'],
    sortable: true,
    type: 'string',
    width: 150
  },
  {
    name: 'Plan Type',
    collectionPath: 'retirementPlan',
    dataPath: 'retirementPlan/planType',
    expand: ['RetirementPlan($select=planType)'],
    facetable: true,
    filterable: true,
    sortable: true,
    type: 'string',
    width: 100
  },
  {
    name: 'RCM Client Type',
    collectionPath: 'retirementPlan',
    dataPath: 'retirementPlan/rcmClientType',
    expand: ['RetirementPlan($select=rcmClientType)'],
    facetable: true,
    filterable: true,
    sortable: true,
    type: 'string',
    width: 100
  },
  {
    name: 'Fiduciary Status',
    dataPath: 'fiduciaryStatus',
    facetable: true,
    filterable: true,
    select: ['fiduciaryStatus'],
    sortable: true,
    type: 'string',
    width: 70
  },
  {
    name: 'Plan Rate',
    collectionPath: 'retirementPlan',
    dataPath: 'retirementPlan/planFeeAmount',
    expand: ['RetirementPlan($select=planFeeAmount)'],
    filterable: true,
    sortable: true,
    type: 'number',
    width: 90
  },
  {
    name: 'Market Value',
    dataPath: 'marketValue',
    filterable: true,
    type: 'number',
    select: ['marketValue'],
    sortable: true,
    width: 150
  },
  {
    name: 'Valuation Date',
    dataPath: 'asOfDate',
    facetable: true,
    filterable: true,
    select: ['asOfDate'],
    sortable: true,
    type: 'date-only',
    width: 90
  },
  {
    name: 'Client Live Date',
    collectionPath: 'retirementPlan',
    dataPath: 'retirementPlan/clientLiveDate',
    expand: ['RetirementPlan($select=clientLiveDate)'],
    filterable: true,
    sortable: true,
    type: 'date-only',
    width: 90
  },
  {
    name: 'Plan Fee Amount',
    collectionPath: 'retirementPlan',
    dataPath: 'retirementPlan/retirementTran/estimatedAnnualRevenue',
    expand: [
      'RetirementPlan($expand=RetirementTran($select=EstimatedAnnualRevenue))'
    ],
    filterable: true,
    hidden: true,
    sortable: true,
    type: 'number',
    width: 110
  },
  {
    name: 'Advisor',
    collectionPath: 'retirementPlan',
    dataPath: 'retirementPlan/advisor/name',
    expand: ['RetirementPlan($expand=Advisor($select=Name))'],
    filterable: true,
    sortable: true,
    type: 'string',
    width: 150
  },
  {
    name: 'Advisor Team',
    collectionPath: 'retirementPlan',
    dataPath: 'retirementPlan/advisor/teamName',
    expand: ['RetirementPlan($expand=Advisor($select=TeamName))'],
    filterable: true,
    sortable: true,
    type: 'string',
    width: 200
  },
  {
    name: 'Division',
    collectionPath: 'retirementPlan',
    dataPath: 'retirementPlan/advisor/regionName',
    expand: ['RetirementPlan($expand=Advisor($select=RegionName))'],
    facetable: true,
    filterable: true,
    sortable: true,
    type: 'string',
    width: 140
  }
]
