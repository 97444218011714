import { IColumnDefinition } from '../core/contracts/IColumnDefinition'
import { BaseColumnDefinitions } from '../shared/BaseColumnDefinitions'
import { CommonColumnDefinitions } from '../shared/CommonColumnDefinitions'
import { CommonColumnIdsEnum } from '../shared/CommonColumnIdsEnum'

enum ColumnIdsEnum {
  accountNumber = 'accountNumber',
  accountManagedType = 'accountManagedType',
  accountRegistrationType = 'accountRegistrationType',
  accountStatus = 'accountStatus',
  accountTaxableStatus = 'accountTaxableStatus',
  accountModelName = 'accountModelName',
  accountModelType = 'accountModelType',
  mtdNetReturn = 'mtdNetReturn',
  ytdNetReturn = 'ytdNetReturn',
  qtdNetReturn = 'qtdNetReturn',
  rmdRemaining = 'rmdRemaining',
  estRMDAmount = 'estRMDAmount',
  retirementAccount = 'retirementAccount',
  lastUpdated = 'lastUpdated',
  revenueYTD = 'revenueYTD',
  revenueMTD = 'revenueMTD',
  revenueQTD = 'revenueQTD',
  revenueT12 = 'revenueT12',
  custodianName = 'custodianName',
  custodianCode = 'custodianCode',
  custodianType = 'custodianType',
  nickname = 'nickname',
  clientNickname = 'clientNickname',
  shortname = 'shortname',
  legalEntityId = 'legalEntityId',
  legalEntityType = 'legalEntityType',
  cashAvaliable = 'cashAvaliable',
  managerFeePercent = 'managerFeePercent',
  clientFeePercent = 'clientFeePercent',
  feeDebitAccount = 'feeDebitAccount',
  marginAgreement = 'marginAgreement',
  multipleMargin = 'multipleMargin',
  pendingManagedAccountFeeDebit = 'pendingManagedAccountFeeDebit',
  cashAvailableToWithdraw = 'cashAvailableToWithdraw',
  marginInterestRate = 'marginInterestRate',
  accountPrimaryEmailAddress = 'accountPrimaryEmailAddress',
  RDOTAccountCategoryCode = 'RDOTAccountCategoryCode',
  RDOTAccountCategoryName = 'RDOTAccountCategoryName',
  liquidAssets = 'liquidAssets',
  netWorth = 'netWorth',
  assets_liabilities = 'assets_liabilities',
  AdvisedOnly = 'AdvisedOnly',
  LoanOutstandingOutsideNfs = 'LoanOutstandingOutsideNfs',
  LoanOutstandingNfs = 'LoanOutstandingNfs',
  AdminReporting = 'AdminReporting',
  AccountKey = 'AccountKey',
  RockConnectAccBalance = 'RockConnectAccBalance',
  Source = 'source',
  Division = 'RegionName',
  Office = 'HubName',
  BusinessSegment = 'BusinessSegment',
  EmployeeAccount = 'employeeCode',
  investmentObjective1 = 'investmentObjective1',
  timeHorizon = 'timeHorizon',
  investmentPurpose = 'investmentPurpose',
  riskTolerance = 'riskTolerance',
  YTD_shortTermLoss = 'YTD_shortTermLoss',
  YTD_shortTermGain = 'YTD_shortTermGain',
  YTD_longTermLoss = 'YTD_longTermLoss',
  YTD_longTermGain = 'YTD_longTermGain',
  YTD_rlzdNetGainLoss = 'YTD_rlzdNetGainLoss',
  householdTags = 'householdTags',
  extAccountAmount = 'extAccountAmount',
  establishDate = 'establishDate'
}

export const AccountColumnIds = { ...ColumnIdsEnum, ...CommonColumnIdsEnum }
export type AccountColumnIds = typeof AccountColumnIds

export const AccountColumnDefinitions: Record<string, IColumnDefinition> = {
  ...CommonColumnDefinitions,
  [AccountColumnIds.legalEntityName]: {
    ...(BaseColumnDefinitions.legalEntityName as IColumnDefinition),
    select: ['LegalEntityName', 'srcClientNumber'],
    searchFields: ['LegalEntityName', 'srcClientNumber']
  },
  [AccountColumnIds.aum]: {
    ...(BaseColumnDefinitions.aum as IColumnDefinition),
    select: ['AccountKPIs/AumManaged', 'feeSummary/clientFeePercent', 'id'],
    type: 'number',
    dataPath: 'AccountKPIs/AumManaged'
  },
  [AccountColumnIds.aus]: {
    ...(BaseColumnDefinitions.aus as IColumnDefinition),
    select: [
      'AccountKPIs/AumBrokerage',
      'AccountKPIs/AumManaged',
      'AccountKPIs/LoanOutstandingNfs',
      'AccountKPIs/LoanOutstandingOutsideNfs',
      'AccountKPIs/annuity',
      'AccountKPIs/AdvisedOnly',
      'AccountKPIs/AdminReportingAssets',
      'AccountKPIs/AccountTotal'
    ],
    type: 'number',
    dataPath: 'AccountKPIs/AccountTotal'
  },
  [AccountColumnIds.brokerageAssets]: {
    ...(BaseColumnDefinitions.brokerageAssets as IColumnDefinition),
    type: 'number',
    dataPath: 'AccountKPIs/AumBrokerage'
  },
  [AccountColumnIds.loansOutstanding]: {
    ...(BaseColumnDefinitions.loansOutstanding as IColumnDefinition),
    type: 'number',
    dataPath: 'AccountKPIs/LoanOutstanding'
  },
  [AccountColumnIds.purchasingPower]: {
    ...(BaseColumnDefinitions.purchasingPower as IColumnDefinition),
    type: 'number',
    dataPath: 'AccountKPIs/cashAvlToTrade'
  },
  [AccountColumnIds.netNewAssets]: {
    ...(BaseColumnDefinitions.netNewAssets as IColumnDefinition),
    type: 'number',
    dataPath: 'AccountKPIs/TOA'
  },
  [AccountColumnIds.netNewMoney]: {
    ...(BaseColumnDefinitions.netNewMoney as IColumnDefinition),
    type: 'number',
    dataPath: 'AccountKPIs/NetNewMoney'
  },
  [AccountColumnIds.annuity]: {
    ...(BaseColumnDefinitions.annuity as IColumnDefinition),
    type: 'number',
    dataPath: 'AccountKPIs/annuity'
  },
  [AccountColumnIds.accountNumber]: {
    id: AccountColumnIds.accountNumber,
    select: [
      'CustodyAccount',
      'VendorTamarac',
      'AdvisorAddedNickName',
      'registrationtype',
      'CustodianType',
      'CustodianName',
      'CustodianCode',
      'gfoCustodyAccount',
      'accountStatus',
      'Shortname',
      'householdId',
      'marginInterestRate',
      'id',
      'accountId'
    ],
    searchFields: [
      'CustodyAccount',
      'AdvisorAddedNickName',
      'gfoCustodyAccount',
      'Shortname'
    ],
    name: 'Account Number',
    type: 'string',
    width: 175,
    dataPath: 'CustodyAccount',
    searchable: true,
    sortable: true,
    filterable: true
  },
  [AccountColumnIds.accountManagedType]: {
    id: AccountColumnIds.accountManagedType,
    name: 'AUS Class',
    dataPath: 'accounttype',
    type: 'string',
    width: 70,
    filterable: true,
    sortable: true,
    facetable: true
  },
  [AccountColumnIds.accountRegistrationType]: {
    id: AccountColumnIds.accountRegistrationType,
    name: 'Account Registration',
    dataPath: 'registrationtype',
    type: 'string',
    width: 70,
    filterable: true,
    sortable: true,
    facetable: true
  },
  [AccountColumnIds.accountStatus]: {
    id: AccountColumnIds.accountStatus,
    name: 'Account Status',
    dataPath: 'accountStatus',
    type: 'string',
    width: 70,
    filterable: true,
    sortable: true,
    facetable: true
  },
  [AccountColumnIds.accountTaxableStatus]: {
    id: AccountColumnIds.accountTaxableStatus,
    name: 'Taxable Status',
    dataPath: 'taxstatus',
    type: 'string',
    width: 80,
    filterable: true,
    sortable: true,
    facetable: true
  },
  [AccountColumnIds.accountModelName]: {
    id: AccountColumnIds.accountModelName,
    name: 'Managed Model Name',
    dataPath: 'modelName',
    type: 'string',
    filterable: true,
    sortable: true,
    facetable: true
  },
  [AccountColumnIds.accountModelType]: {
    id: AccountColumnIds.accountModelType,
    name: 'Managed Model Type',
    dataPath: 'modelType',
    type: 'string',
    filterable: true,
    sortable: true,
    facetable: true
  },
  [AccountColumnIds.qtdNetReturn]: {
    id: AccountColumnIds.qtdNetReturn,
    name: 'QTD Performance',
    dataPath: 'AcctPerformance/QTDNetReturn',
    type: 'number',
    width: 80,
    filterable: true,
    sortable: true
  },
  [AccountColumnIds.mtdNetReturn]: {
    id: AccountColumnIds.mtdNetReturn,
    name: 'MTD Performance',
    dataPath: 'AcctPerformance/MTDNetReturn',
    type: 'number',
    width: 80,
    filterable: true,
    sortable: true
  },
  [AccountColumnIds.ytdNetReturn]: {
    id: AccountColumnIds.ytdNetReturn,
    name: 'YTD Performance',
    dataPath: 'AcctPerformance/YTDNetReturn',
    type: 'number',
    width: 80,
    filterable: true,
    sortable: true
  },
  [AccountColumnIds.rmdRemaining]: {
    id: AccountColumnIds.rmdRemaining,
    name: 'RMD Remaining',
    dataPath: 'RMD/estRMDAmountRemain',
    type: 'number',
    width: 100,
    filterable: true,
    sortable: true
  },
  [AccountColumnIds.estRMDAmount]: {
    id: AccountColumnIds.estRMDAmount,
    name: 'RMD Amount',
    dataPath: 'RMD/estRMDAmount',
    type: 'number',
    width: 100,
    filterable: true,
    sortable: true
  },
  [AccountColumnIds.lastUpdated]: {
    id: AccountColumnIds.lastUpdated,
    name: 'Last Updated Date',
    dataPath: 'LastUpdatedAt',
    type: 'date',
    filterable: true,
    sortable: true
  },
  [AccountColumnIds.retirementAccount]: {
    id: AccountColumnIds.retirementAccount,
    name: 'Retirement Account',
    dataPath: 'retirementAccount',
    type: 'string',
    width: 70,
    filterable: true,
    sortable: true,
    facetable: true
  },
  [AccountColumnIds.revenueMTD]: {
    id: AccountColumnIds.revenueMTD,
    name: 'MTD Revenue',
    dataPath: 'revenueDet/MTDcompRevenue',
    type: 'number',
    width: 100,
    filterable: true,
    sortable: true
  },
  [AccountColumnIds.revenueQTD]: {
    id: AccountColumnIds.revenueQTD,
    name: 'QTD Revenue',
    dataPath: 'revenueDet/QTDcompRevenue',
    type: 'number',
    width: 100,
    filterable: true,
    sortable: true
  },
  [AccountColumnIds.revenueT12]: {
    id: AccountColumnIds.revenueT12,
    name: 'T12 Revenue',
    dataPath: 'revenueDet/ttmrevenue',
    type: 'number',
    width: 100,
    filterable: true,
    sortable: true
  },
  [AccountColumnIds.revenueYTD]: {
    id: AccountColumnIds.revenueYTD,
    name: 'YTD Revenue',
    dataPath: 'revenueDet/YTDcompRevenue',
    type: 'number',
    width: 100,
    filterable: true,
    sortable: true
  },
  [AccountColumnIds.custodianName]: {
    id: AccountColumnIds.custodianName,
    name: 'Custodian Name',
    dataPath: 'CustodianName',
    type: 'string',
    width: 140,
    filterable: true,
    sortable: true,
    facetable: true
  },
  [AccountColumnIds.custodianCode]: {
    id: AccountColumnIds.custodianCode,
    name: 'Custodian Code',
    dataPath: 'CustodianCode',
    type: 'string',
    width: 100,
    filterable: true,
    sortable: true,
    facetable: true
  },
  [AccountColumnIds.custodianType]: {
    id: AccountColumnIds.custodianType,
    name: 'Custodian Type',
    dataPath: 'CustodianType',
    type: 'string',
    width: 100,
    filterable: true,
    sortable: true,
    facetable: true
  },
  [AccountColumnIds.nickname]: {
    id: AccountColumnIds.nickname,
    name: 'Nickname',
    dataPath: 'AdvisorAddedNickName',
    type: 'string',
    width: 100,
    filterable: true,
    sortable: true,
    searchable: true
  },
  [AccountColumnIds.clientNickname]: {
    id: AccountColumnIds.clientNickname,
    name: 'Client Nickname',
    dataPath: 'AccountNickname',
    type: 'string',
    width: 100,
    filterable: true,
    sortable: true,
    searchable: true
  },
  [AccountColumnIds.shortname]: {
    id: AccountColumnIds.shortname,
    name: 'Short Name',
    dataPath: 'Shortname',
    type: 'string',
    width: 100,
    filterable: true,
    sortable: true,
    searchable: true
  },
  [AccountColumnIds.legalEntityId]: {
    id: AccountColumnIds.legalEntityId,
    select: ['LegalEntityID', 'LegalEntityName'],
    name: 'Legal Entity Id',
    dataPath: 'LegalEntityID',
    type: 'string',
    filterable: true,
    selectable: true
  },
  [AccountColumnIds.legalEntityType]: {
    id: AccountColumnIds.legalEntityType,
    name: 'Entity Type',
    dataPath: 'legalEntityType',
    width: 100,
    type: 'string',
    filterable: true,
    selectable: true,
    searchable: false,
    facetable: true
  },
  [AccountColumnIds.cashAvaliable]: {
    id: AccountColumnIds.cashAvaliable,
    name: 'Cash Avaliable',
    dataPath: 'AccountKPIs/cashAvlToTrade',
    width: 100,
    type: 'string',
    filterable: true,
    selectable: false,
    searchable: false
  },
  [AccountColumnIds.clientFeePercent]: {
    id: AccountColumnIds.clientFeePercent,
    name: 'Client Fee Percent',
    dataPath: 'feeSummary/clientFeePercent',
    type: 'number',
    width: 70,
    filterable: true,
    sortable: true
  },
  [AccountColumnIds.managerFeePercent]: {
    id: AccountColumnIds.managerFeePercent,
    name: 'Manager Fee Percent',
    dataPath: 'feeSummary/managerFeePercent',
    type: 'number',
    width: 70,
    filterable: true,
    sortable: true
  },
  [AccountColumnIds.feeDebitAccount]: {
    id: AccountColumnIds.feeDebitAccount,
    name: 'Alternate Account for Client Fee Debit',
    dataPath: 'feeSummary/debitAccount',
    type: 'string',
    width: 70,
    filterable: true,
    sortable: true,
    searchable: true
  },
  [AccountColumnIds.cashAvailableToWithdraw]: {
    id: AccountColumnIds.cashAvailableToWithdraw,
    name: 'Cash Available To Withdraw',
    dataPath: 'AccountKPIs/cashAvlToWithDraw',
    type: 'number',
    width: 120,
    filterable: true,
    sortable: true
  },
  [AccountColumnIds.pendingManagedAccountFeeDebit]: {
    id: AccountColumnIds.pendingManagedAccountFeeDebit,
    select: ['feeSummary/clientFee', 'feeSummary/totalPendingAccountDebit'],
    name: 'Total Pending Debit (Client Fee)',
    dataPath: 'feeSummary/totalPendingAccountDebit',
    type: 'number',
    width: 120,
    filterable: true,
    sortable: true
  },
  [AccountColumnIds.marginAgreement]: {
    id: AccountColumnIds.marginAgreement,
    name: 'Margin Agreement',
    dataPath: 'marginAgreement',
    type: 'string',
    filterable: true,
    sortable: true,
    facetable: true,
    width: 75
  },
  [AccountColumnIds.multipleMargin]: {
    id: AccountColumnIds.multipleMargin,
    name: 'Multiple Margin',
    dataPath: 'multipleMargin',
    type: 'string',
    filterable: true,
    sortable: true,
    facetable: true,
    width: 160
  },
  [AccountColumnIds.marginInterestRate]: {
    id: AccountColumnIds.marginInterestRate,
    name: 'Custom Margin Interest Spread',
    dataPath: 'marginInterestRate',
    type: 'number',
    filterable: true,
    sortable: true,
    width: 160
  },
  [AccountColumnIds.accountPrimaryEmailAddress]: {
    id: AccountColumnIds.accountPrimaryEmailAddress,
    name: 'Account Primary Email Address',
    dataPath: 'accountPrimaryEmailAddress',
    type: 'string',
    filterable: true,
    sortable: true,
    facetable: true,
    width: 160
  },
  [AccountColumnIds.RDOTAccountCategoryCode]: {
    id: AccountColumnIds.RDOTAccountCategoryCode,
    name: 'RCM Account Category Code',
    dataPath: 'RDOTAccountCategoryCode',
    type: 'string',
    filterable: true,
    sortable: true,
    facetable: true,
    width: 80
  },
  [AccountColumnIds.RDOTAccountCategoryName]: {
    id: AccountColumnIds.RDOTAccountCategoryName,
    name: 'RCM Account Category',
    dataPath: 'RDOTAccountCategoryName',
    type: 'string',
    filterable: true,
    sortable: true,
    facetable: true,
    width: 160
  },
  [AccountColumnIds.liquidAssets]: {
    id: AccountColumnIds.liquidAssets,
    name: 'Liquid Assets',
    dataPath: 'liquidAssets',
    type: 'number',
    filterable: true,
    sortable: true,
    width: 160
  },
  [AccountColumnIds.netWorth]: {
    id: AccountColumnIds.netWorth,
    name: 'Net Worth',
    dataPath: 'NetWorth',
    type: 'number',
    filterable: true,
    sortable: true,
    width: 160
  },
  [AccountColumnIds.assets_liabilities]: {
    id: AccountColumnIds.assets_liabilities,
    name: 'Other Assets / Liabilities',
    dataPath: 'AccountKPIs/AssetsLiabilities',
    type: 'number',
    filterable: true,
    sortable: true,
    width: 160
  },
  [AccountColumnIds.AdvisedOnly]: {
    id: AccountColumnIds.AdvisedOnly,
    name: 'Advised Only',
    dataPath: 'AccountKPIs/AdvisedOnly',
    type: 'number',
    searchable: false,
    filterable: true,
    sortable: true
  },
  [AccountColumnIds.LoanOutstandingOutsideNfs]: {
    id: AccountColumnIds.LoanOutstandingOutsideNfs,
    name: 'Loan Outstanding Outside NFS',
    dataPath: 'AccountKPIs/LoanOutstandingOutsideNfs',
    type: 'number',
    searchable: false,
    filterable: true,
    sortable: true
  },
  [AccountColumnIds.LoanOutstandingNfs]: {
    id: AccountColumnIds.LoanOutstandingNfs,
    name: 'Loan Outstanding NFS',
    dataPath: 'AccountKPIs/LoanOutstandingNfs',
    type: 'number',
    searchable: false,
    filterable: true,
    sortable: true
  },
  [AccountColumnIds.AdminReporting]: {
    id: AccountColumnIds.AdminReporting,
    name: 'Admin Reporting',
    dataPath: 'AccountKPIs/AdminReportingAssets',
    type: 'number',
    searchable: false,
    filterable: true,
    sortable: true
  },
  [AccountColumnIds.AccountKey]: {
    id: AccountColumnIds.AccountKey,
    name: 'Account Key',
    dataPath: 'accountkey',
    type: 'string',
    filterable: true,
    sortable: true,
    facetable: false,
    width: 160
  },
  [AccountColumnIds.RockConnectAccBalance]: {
    id: AccountColumnIds.RockConnectAccBalance,
    name: 'Account Balances',
    dataPath: 'RockConnectAccBalance',
    type: 'number',
    searchable: false,
    filterable: true,
    sortable: true
  },
  [AccountColumnIds.Source]: {
    id: AccountColumnIds.Source,
    name: 'Source',
    dataPath: 'Accountsource',
    type: 'string',
    filterable: true,
    sortable: true,
    facetable: true,
    width: 80
  },
  [AccountColumnIds.Division]: {
    id: AccountColumnIds.Division,
    name: 'Division',
    dataPath: 'RegionName',
    type: 'string',
    filterable: true,
    sortable: true,
    facetable: true,
    width: 100
  },
  [AccountColumnIds.Office]: {
    id: AccountColumnIds.Office,
    name: 'Office',
    dataPath: 'HubName',
    type: 'string',
    filterable: true,
    sortable: true,
    facetable: true,
    width: 100
  },
  [AccountColumnIds.BusinessSegment]: {
    id: AccountColumnIds.BusinessSegment,
    name: 'Business Segment',
    dataPath: 'BusinessSegment',
    type: 'string',
    filterable: true,
    sortable: true,
    facetable: true,
    width: 50
  },
  [AccountColumnIds.EmployeeAccount]: {
    id: AccountColumnIds.EmployeeAccount,
    name: 'Employee Account',
    dataPath: 'employeeCode',
    type: 'string',
    filterable: true,
    sortable: true,
    facetable: true,
    width: 50
  },
  [AccountColumnIds.investmentObjective1]: {
    id: AccountColumnIds.investmentObjective1,
    name: 'Investment Objective',
    dataPath: 'investmentObjective1',
    type: 'string',
    filterable: true,
    sortable: true,
    width: 80
  },
  [AccountColumnIds.timeHorizon]: {
    id: AccountColumnIds.timeHorizon,
    name: 'Time Horizon',
    dataPath: 'timeHorizon',
    type: 'string',
    filterable: true,
    sortable: true,
    width: 80
  },
  [AccountColumnIds.investmentPurpose]: {
    id: AccountColumnIds.investmentPurpose,
    name: 'Investment Purpose',
    dataPath: 'investmentPurpose',
    type: 'string',
    filterable: true,
    sortable: true,
    width: 80
  },
  [AccountColumnIds.riskTolerance]: {
    id: AccountColumnIds.riskTolerance,
    name: 'Risk Tolerance',
    dataPath: 'riskTolerance',
    type: 'string',
    filterable: true,
    sortable: true,
    width: 80
  },
  [AccountColumnIds.YTD_shortTermLoss]: {
    id: AccountColumnIds.YTD_shortTermLoss,
    name: 'YTD Short Term Loss',
    dataPath: 'shortTermLoss',
    type: 'number',
    filterable: true,
    sortable: true,
    width: 100
  },
  [AccountColumnIds.YTD_shortTermGain]: {
    id: AccountColumnIds.YTD_shortTermGain,
    name: 'YTD Short Term Gain',
    dataPath: 'shortTermGain',
    type: 'number',
    filterable: true,
    sortable: true,
    width: 100
  },
  [AccountColumnIds.YTD_longTermLoss]: {
    id: AccountColumnIds.YTD_longTermLoss,
    name: 'YTD Long Term Loss',
    dataPath: 'longTermLoss',
    type: 'number',
    filterable: true,
    sortable: true,
    width: 100
  },
  [AccountColumnIds.YTD_longTermGain]: {
    id: AccountColumnIds.YTD_longTermGain,
    name: 'YTD Long Term Gain',
    dataPath: 'longTermGain',
    type: 'number',
    filterable: true,
    sortable: true,
    width: 100
  },
  [AccountColumnIds.YTD_rlzdNetGainLoss]: {
    id: AccountColumnIds.YTD_rlzdNetGainLoss,
    name: 'YTD Net Realized Gain/Loss',
    dataPath: 'rlzdNetGainLoss',
    type: 'number',
    filterable: true,
    sortable: true,
    width: 100
  },
  [AccountColumnIds.householdTags]: {
    id: AccountColumnIds.householdTags,
    name: 'Household Tags',
    collectionPath: 'HouseholdTags',
    dataPath: 'Name',
    type: 'custom',
    facetable: true,
    filterable: true,
    searchable: true,
    searchFields: ['HouseholdTags/Name'],
    preview: true
  },
  [AccountColumnIds.extAccountAmount]: {
    id: AccountColumnIds.extAccountAmount,
    name: 'External Assets',
    dataPath: 'extAccountAmount',
    type: 'number',
    filterable: true,
    sortable: true,
    width: 160
  },
  [AccountColumnIds.establishDate]: {
    id: AccountColumnIds.establishDate,
    name: 'Established Date',
    dataPath: 'establishDate',
    type: 'date-only',
    filterable: true,
    sortable: true
  }
}
