import { IOdataListColumnDefinition } from 'features/OdataList/common/types'

export interface IAccountModel {
  registeredRep: string
}

export type PlatformFeeHistoryListColumnName =
  | 'Account Number'
  | 'Legal Entity Name'
  | 'Bill Date'
  | 'Fee Start Date'
  | 'Fee End Date'
  | 'Fee Status'
  | 'Unadjusted AUM'
  | 'Net Flows'
  | 'Adjusted AUM'
  | 'Household AUM'
  | 'Adjusted AUM After Cap'
  | 'Gross Fee'
  | 'Gross Fee Percent'
  | 'Gross Fee Credit'
  | 'Gross Fee Credit Percent'
  | 'Net Fee'
  | 'Net Fee Percent'
  | 'Quarter End Date'
  | 'Month End Date'
  | 'Rep Code'
  | 'Division'
  | 'Team'
  | 'Household'
  | 'Rep Name'
  | 'Debit Account Number'

export interface IPlatformFeeHistoryListColumnDefinition
  extends IOdataListColumnDefinition {
  name: PlatformFeeHistoryListColumnName
}

const defaultColumn: Partial<IPlatformFeeHistoryListColumnDefinition> = {
  filterable: true,
  sortable: true
}

export const platformFeeHistoryListColumns: IPlatformFeeHistoryListColumnDefinition[] =
  [
    {
      ...defaultColumn,
      name: 'Account Number',
      dataPath: 'accountNumber',
      type: 'string',
      searchFields: ['accountNumber'],
      width: 100
    },
    {
      ...defaultColumn,
      name: 'Legal Entity Name',
      dataPath: 'account/party/partyName',
      type: 'string',
      searchFields: ['account/party/partyName'],
      expand: ['account($expand=party($select=partyName))'],
      width: 220
    },
    {
      ...defaultColumn,
      name: 'Bill Date',
      type: 'date-only',
      dataPath: 'feeSchedule/billDate',
      expand: ['feeSchedule($select=billDate)'],
      width: 90
    },
    {
      ...defaultColumn,
      name: 'Fee Start Date',
      dataPath: 'billStartDate',
      type: 'date-only',
      width: 90
    },
    {
      ...defaultColumn,
      name: 'Fee End Date',
      dataPath: 'billEndDate',
      type: 'date-only',
      width: 90
    },
    {
      ...defaultColumn,
      name: 'Fee Status',
      dataPath: 'feeStatus',
      type: 'string',
      facetable: true,
      width: 100
    },
    {
      ...defaultColumn,
      name: 'Unadjusted AUM',
      dataPath: 'unadjustedAum',
      type: 'number',
      width: 150
    },
    {
      ...defaultColumn,
      name: 'Net Flows',
      dataPath: 'netFlows',
      type: 'number',
      width: 120
    },
    {
      ...defaultColumn,
      name: 'Adjusted AUM',
      dataPath: 'adjustedAum',
      type: 'number',
      width: 150
    },
    {
      ...defaultColumn,
      name: 'Household AUM',
      dataPath: 'householdAum',
      type: 'number',
      width: 180
    },
    {
      ...defaultColumn,
      name: 'Adjusted AUM After Cap',
      dataPath: 'adjustedAumAfterCap',
      type: 'number',
      width: 150
    },
    {
      ...defaultColumn,
      name: 'Gross Fee',
      dataPath: 'grossFee',
      type: 'number',
      width: 100
    },
    {
      ...defaultColumn,
      name: 'Gross Fee Credit',
      dataPath: 'creditFee',
      type: 'number',
      width: 100
    },
    {
      ...defaultColumn,
      name: 'Net Fee',
      dataPath: 'netFee',
      type: 'number',
      width: 100
    },
    {
      ...defaultColumn,
      name: 'Gross Fee Percent',
      dataPath: 'grossFeePct',
      type: 'number',
      width: 80
    },
    {
      ...defaultColumn,
      name: 'Gross Fee Credit Percent',
      dataPath: 'creditFeePct',
      type: 'number',
      width: 80
    },
    {
      ...defaultColumn,
      name: 'Net Fee Percent',
      dataPath: 'netFeePct',
      type: 'number',
      width: 80
    },
    {
      ...defaultColumn,
      name: 'Debit Account Number',
      dataPath: 'billToAccount',
      type: 'string',
      searchFields: ['billToAccount'],
      width: 100
    },
    {
      ...defaultColumn,
      name: 'Quarter End Date',
      dataPath: 'quarterEndDate',
      type: 'date-only',
      width: 90
    },
    {
      ...defaultColumn,
      name: 'Month End Date',
      dataPath: 'monthEndDate',
      type: 'date-only',
      width: 90
    },
    {
      ...defaultColumn,
      name: 'Rep Code',
      dataPath: 'account/registeredRep',
      type: 'string',
      facetable: true,
      expand: ['account($select=registeredRep)'],
      width: 60
    },
    {
      ...defaultColumn,
      name: 'Rep Name',
      dataPath: 'account/advisor/name',
      type: 'string',
      facetable: true,
      expand: ['account($expand=advisor($select=name))'],
      width: 140
    },
    {
      ...defaultColumn,
      name: 'Division',
      dataPath: 'account/advisor/regionName',
      type: 'string',
      facetable: true,
      expand: ['account($expand=advisor($select=regionName))'],
      width: 140
    },
    {
      ...defaultColumn,
      name: 'Team',
      dataPath: 'account/advisor/teamName',
      type: 'string',
      facetable: true,
      expand: ['account($expand=advisor($select=teamName))'],
      width: 140
    },
    {
      ...defaultColumn,
      name: 'Household',
      dataPath: 'account/Household/HouseholdName',
      type: 'string',
      searchFields: ['account/Household/HouseholdName'],
      expand: ['account($expand=Household($select=householdName))'],
      width: 200
    }
  ]
