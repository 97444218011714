import {
  createOdataListWithFacetsStore,
  IODataListState
} from 'features/OdataList/store/odataListWithFacetsStore'
import { keyBy } from 'lodash'
import { Reducer } from 'redux'
import { call, cancelled } from 'typed-redux-saga'
import { IOdataRequest } from '../../../../../../../api/odata.types'
import { IListsFilter } from '../../../../../../../features/Lists/core/contracts/IListsFilter'
import { convertColumnTypeToFilterType } from '../../../../../../../features/OdataList/common/service'
import { IApiOptions } from '../../../../../../../shared/contracts/IApiOptions'
import { AppState } from '../../../../../../../store'
import { fetchOdataEndpoint, getDatahubApiOptions } from '../../../shared'
import { IRockConnectAssetFee } from '../../api/rockConnectAssetFee'
import { feeDashboardListColumns } from './dashboardListColumns'

export const fetchPendingFees = (
  options: IApiOptions,
  request: IOdataRequest
) => {
  const path = 'datahub/RockConnectAssetFees'
  return fetchOdataEndpoint<IRockConnectAssetFee>({ ...options, path }, request)
}

export const saga_fetchPendingFees = function* (request: IOdataRequest) {
  const [apiOptions, cancelTokenSource] = yield* call(getDatahubApiOptions)
  try {
    return yield* call(fetchPendingFees, apiOptions, request)
  } finally {
    if (yield* cancelled()) {
      cancelTokenSource.cancel()
    }
  }
}

const rootSelector = (state: AppState) =>
  state.modules.advisory.modules.fees.modules.dashboard.feeDashboardList

const uiFilters = keyBy(
  feeDashboardListColumns
    .filter((x) => x.filterable)
    .map((column): IListsFilter => {
      const base = {
        id: column.name,
        name: column.name,
        type: convertColumnTypeToFilterType(column),
        dataPath: column.dataPath,
        hasValue: false
      }

      return base
    }),
  ({ id }) => id
)

const selectedColumnIds = feeDashboardListColumns.map((x) => x.name)

const store = createOdataListWithFacetsStore({
  prefix: `@modules/@advisory/@modules/@fees/@modules/@feeDashboard/@feeDashboardList`,
  getOdataResults: saga_fetchPendingFees,
  initialState: {
    data: {},
    ui: {
      columns: feeDashboardListColumns,
      selectedColumns: selectedColumnIds,
      filters: uiFilters,
      sortBy: {
        direction: 'asc',
        name: 'Remaining Cash After Total Pending Debit'
      }
    },
    facets: {}
  },
  rootSelector
})

export const feeDashboardListReducer = store.reducer as Reducer<
  IODataListState<IRockConnectAssetFee>
>
export const feeDashboardListSagas = store.sagas
export const {
  uiActions: feeDashboardListUiActions,
  facetActions: feeDashboardListFacetActions
} = store.actions
export const {
  uiSelectors: feeDashboardListUiSelectors,
  facetSelectors: feeDashboardListFacetSelectors
} = store.selectors
